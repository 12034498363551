import React, { useContext, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import AccountCard from '../../components/AccountCard';
import Loading from '../../components/Loading';
import SearchContext from '../../contexts/SearchContext';
import { useAccount } from '../../contexts/AccountContext';
import styles from './Home.styles';

function Home() {
  const classes = styles();
  const { setSearch } = useContext(SearchContext);
  const { loadAccounts, accounts, setActiveAccount, clearActiveAccount, isLoadingAccounts } =
    useAccount();

  useEffect(() => {
    setSearch(null);
  }, [setSearch]);

  // Set overflow to auto in the body so that client accounts will scroll if needed
  useEffect(() => {
    document.body.style.overflow = 'auto';
  });

  useEffect(() => {
    clearActiveAccount();
    loadAccounts();
  }, [clearActiveAccount, loadAccounts]);

  if (isLoadingAccounts) return <Loading displayText="Loading Accounts" />;

  if (!accounts.length) {
    return '';
  }
  return (
    <main className={classes.cardArea}>
      <header>
        <h1>Client Selection</h1>
      </header>
      <ImageList
        className="client-grid"
        sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 130px))!important' }}
      >
        {accounts
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((account) => (
            <AccountCard key={account.id} account={account} setActiveAccount={setActiveAccount} />
          ))}
      </ImageList>
    </main>
  );
}

export default Home;
