import { createTheme } from '@mui/material/styles';
import colors from './colors';

export default createTheme({
  palette: {
    default: {
      main: colors.black,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%)',
          backgroundColor: '#fff',
          border: '1px solid #dcdcdc',
          padding: '4px',
          width: '225px',
          zIndex: '0',
          minHeight: '4.5em',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '0',
          padding: '0',
        },
      },
    },
  },
});
