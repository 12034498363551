import { Box, Button, Fade, Modal, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles({
  buttonSpacing: {
    display: 'flex',
    float: 'right',
    paddingTop: '40px',
  },
  outlineButton: {
    borderColor: '#6991CB',
    color: '#6991CB',
    margin: '10px',
  },
  filledButton: {
    backgroundColor: '#6991CB',
    color: 'white',
    margin: '10px',
    '&:hover': {
      backgroundColor: '#69a1cb',
    },
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    backgroundColor: '#ffffff',
    padding: '30px',
    boxShadow: 24,
    p: 4,
  },
});

function IdleTimeoutModal({ modalOpen, setModalOpen, closeModal }) {
  const classes = useStyles();
  const [seconds, setSeconds] = useState(300);

  const handleClose = () => {
    setSeconds(300);
    setModalOpen(false);
  };

  const handleLogout = useCallback(() => {
    setSeconds(300);
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    let interval = null;
    if (modalOpen) {
      interval = setInterval(() => {
        setSeconds(() => seconds - 1);
      }, 1000);
      if (seconds === 0) {
        handleLogout();
      }
    } else if (!modalOpen && seconds !== 300) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds, modalOpen, handleLogout]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalOpen}>
        <Box className={classes.modalBox}>
          <Typography variant="h6" component="h2">
            Are you still there?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            You will be logged out in {Math.floor(seconds / 60)} minute(s) and {seconds % 60}{' '}
            second(s).
          </Typography>
          <div className={classes.buttonSpacing}>
            <Button variant="outlined" className={classes.outlineButton} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" className={classes.filledButton} onClick={handleLogout}>
              Logout Now
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default IdleTimeoutModal;
