import React from 'react';
import DaughertyReportLegend from './DaughertyReportLegend';
import styles from './DaughertyLegends.styles';

function DaughertyLegends() {
  const classes = styles();

  return (
    <div className={classes.legend}>
      <DaughertyReportLegend />
    </div>
  );
}

export default DaughertyLegends;
