import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Link, ToggleButton } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import DaughertyOrgChartNode from './DaughertyOrgChartNode';
import styles from './DaughertyOrgChart.styles';
import searchViews from '../utils/searchViews';

// Will allow us to extend the capabilities of OrgChartNodes without changing proptypes in components
export const orgChartContext = React.createContext({ showConsultants: true });
const OrgChartProvider = orgChartContext.Provider;

function DaughertyOrgChart({
  history,
  employeeData,
  subLevelsToShow,
  handleSelectedEmployeeId,
  currentView,
}) {
  const classes = styles();
  const employees = employeeData;
  const rootManager =
    employeeData.length > 0
      ? {
          key: employees[0].id,
          account: employees[0].accountName,
          department: employees[0].department,
          name: `${employees[0].firstName} ${employees[0].lastName}`,
          title: employees[0].title,
          lastContactDate: employees[0].last_contact_date,
        }
      : undefined;
  const directReports = employees[0].children;

  const { routeAccount } = useParams();
  const [orgChartState, setOrgChartState] = React.useState({ showConsultants: true });

  const navUpId =
    currentView === searchViews.views.CLIENT_VIEW
      ? employeeData[0].clientManagerId
      : employeeData[0].daughertyManagerId;

  const handleToggleConsultants = () => {
    setOrgChartState((oldState) => ({
      ...oldState,
      showConsultants: !oldState.showConsultants,
    }));
  };

  return (
    <>
      <div>
        <ToggleButton
          color="primary"
          selected={orgChartState.showConsultants}
          fullWidth={false}
          value={orgChartState.value}
          onClick={handleToggleConsultants}
        >
          Show Consultants
        </ToggleButton>
      </div>
      <OrgChartProvider value={orgChartState}>
        {navUpId && (
          <div style={{ margin: '0px auto' }}>
            <Link
              style={{ display: navUpId ? 'block' : 'none' }}
              component={RouterLink}
              key={`crumb_${navUpId}`}
              to={`/hierarchy/${navUpId}/${routeAccount}`}
              onClick={() => {
                handleSelectedEmployeeId(navUpId);
              }}
              data-testid="navigate-up-button"
            >
              <div className={classes.navigateUpIcons}>
                <span
                  className={classes.navigateUpIcon}
                  aria-hidden="true"
                  role="presentation"
                  title="Navigate Up"
                />
                <span className="org-nav-icon" />
              </div>
            </Link>
          </div>
        )}
        <DaughertyOrgChartNode
          key={rootManager.key}
          id={rootManager.key}
          name={rootManager.name}
          title={rootManager.title}
          department={rootManager.department}
          lastContactDate={rootManager.last_contact_date}
          directReports={directReports}
          subLevelsToShow={subLevelsToShow - 1}
          handleSelectedEmployeeId={handleSelectedEmployeeId}
          employeeData={employeeData[0]}
          history={history}
          rootNode
        />
      </OrgChartProvider>
    </>
  );
}

export default DaughertyOrgChart;
