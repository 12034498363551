/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import '../App.css';
import PropTypes from 'prop-types';
import relations from '../utils/relations';
import { generateLinkedInUrl, generateSalesforceUrl } from '../utils/links';
import toastMessage from '../utils/toastMessage/toastMessageUtility';
// eslint-disable-next-line import/no-cycle
import DaughertyNodesSubset from './DaughertyNodesSubset';
import SearchContext from '../contexts/SearchContext';
import searchViews from '../utils/searchViews';
import DaughertyConsultantCard from './DaughertyConsultantCard';
import AdminUserContext from '../contexts/AdminUserContext';
// eslint-disable-next-line import/no-cycle
import { orgChartContext } from './DaughertyOrgChart';

function DaughertyOrgChartNode({
  id,
  name,
  title,
  department,
  directReports,
  subLevelsToShow,
  handleSelectedEmployeeId,
  employeeData,
  rootNode,
}) {
  const { currentView } = useContext(SearchContext);
  const hasChildren = directReports.length > 0;
  const showChildren = subLevelsToShow >= 0;
  const hasChildrenCssClass = hasChildren ? 'node-has-children' : 'no-children';
  const showChildrenCssClass = showChildren ? 'node-show-children' : 'not-show-children';
  const rStatus = relations[employeeData.relationshipStatus]; // TODO: Check if this logic is sound. Getting occassional errors related to relationship status
  const { isClientUser } = useContext(AdminUserContext);
  const cardClickable = subLevelsToShow !== 0;
  const chart = useContext(orgChartContext);

  const handleCardClick = () => {
    if (cardClickable) {
      handleSelectedEmployeeId(id);
    }
  };

  const handleLinkedInClick = (event) => {
    event.stopPropagation();
    global.open(
      generateLinkedInUrl(employeeData.firstName, employeeData.lastName, employeeData.accountName),
    );
  };

  const handleSalesforceClick = (event) => {
    event.stopPropagation();
    global.open(generateSalesforceUrl(id));
  };

  const styles = {
    daughertyCard: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      margin: 'auto',
      marginTop: `${rootNode ? 0 : 10}px`,
      marginBottom: `${rootNode ? 0 : 20}px`,
      padding: '0px',
      width: '225px',
      textAlign: 'center',
      boxSizing: 'border-box',
      zIndex: '0',
    },
  };

  function renderEmployeeInfo(css) {
    return (
      <div className={`employee-info-container ${css}`} id={name} data-testid={name}>
        <p className="employee-name">{name}</p>
        <p className="employee-title">{title || 'No Title Found'}</p>
        {department && <p className="employee-department">{department}</p>}
        <div className="social-icons">
          <span
            className="linkedin-png"
            onClick={handleLinkedInClick}
            title="View in LinkedIn"
            aria-label="View in LinkedIn"
            role="link"
            tabIndex={0}
          />
          <span
            className="salesforce-png"
            onClick={handleSalesforceClick}
            title="View in Salesforce"
            aria-label="View in Salesforce"
            role="link"
            tabIndex={0}
          />
        </div>
      </div>
    );
  }
  function renderEmployeeOrConsultant(employeeOrConsultantData) {
    if (employeeOrConsultantData._type === 'DbsConsultant') {
      return (
        <DaughertyConsultantCard
          contact={{
            firstName: employeeOrConsultantData.firstName,
            lastName: employeeOrConsultantData.lastName,
            title: employeeOrConsultantData.title,
            primaryLOS: employeeOrConsultantData.primaryLOS,
            deliveryRole: employeeOrConsultantData.deliveryRole,
            clientManagerName: employeeOrConsultantData.clientManagerName,
            clientManagerId: employeeOrConsultantData.clientManagerId,
            daughertyManagerName: employeeOrConsultantData.daughertyManagerName,
            daughertyManagerId: employeeOrConsultantData.daughertyManagerId,
            totalReports: employeeOrConsultantData.totalReports,
          }}
          reportsToClickHandler={handleSelectedEmployeeId}
          clickable={cardClickable}
          currentView={currentView}
        />
      );
    }
    if (employeeOrConsultantData._type === 'Employee' && rStatus != null && isClientUser) {
      return <div data-testid="permissions-background-css">{renderEmployeeInfo(rStatus.css)}</div>;
    }
    return renderEmployeeInfo();
  }

  // If the consultant filter is set to false and this employee is of type DbsConsultant, do not render this node
  const showConsultant =
    // eslint-disable-next-line react/destructuring-assignment
    (chart.showConsultants && employeeData._type === 'DbsConsultant') ||
    employeeData._type === 'Employee';

  return (
    <div className={`node-wrapper ${hasChildrenCssClass} ${showChildrenCssClass}`}>
      {showConsultant && (
        <>
          {currentView === searchViews.views.DAUGHERTY_VIEW && (
            /*
          Daugherty Cards need to be adjusted in the future. Currently we are putting them on an invisible box that handles the child node.
          This happens to reflect the Client View that is being set up below - Daugherty Cards are a separate entity, while Client Cards are set up right here below.
          Action needs to happen for both of the cards being handled in the same way
          */
            <div
              className={`${rootNode ? 'root-node' : 'child-node'} `}
              id={id}
              style={styles.daughertyCard}
              onClick={handleCardClick}
              role="button"
              tabIndex={0}
            >
              <DaughertyConsultantCard
                contact={{
                  firstName: employeeData.firstName,
                  lastName: employeeData.lastName,
                  title: employeeData.title,
                  primaryLOS: employeeData.primaryLOS,
                  deliveryRole: employeeData.deliveryRole,
                  clientManagerName: employeeData.clientManagerName,
                  clientManagerId: employeeData.clientManagerId,
                  daughertyManagerName: employeeData.daughertyManagerName,
                  daughertyManagerId: employeeData.daughertyManagerId,
                  totalReports: employeeData.totalReports,
                }}
                reportsToClickHandler={handleSelectedEmployeeId}
                clickable={cardClickable}
              >
                <div className="reports pills daugherty-count">
                  {employeeData.countDBSConsultants}
                </div>
              </DaughertyConsultantCard>
              {employeeData.countDBSConsultants > 0 && !showChildren && (
                <div className="node-subordinate-indicator" data-nodetype={employeeData._type}>
                  <span className="org-nav-icon" />
                  <span className="drill-down-icon" />
                </div>
              )}
            </div>
          )}
          {currentView === searchViews.views.CLIENT_VIEW && (
            <div
              className={`
          ${cardClickable && employeeData._type !== 'DbsConsultant' ? 'green-border' : ''}
          ${rootNode ? 'root-node' : 'child-node'} d-node`}
              id={id}
              data-nodetype={employeeData._type}
              style={cardClickable ? { cursor: 'pointer' } : { cursor: 'null' }}
              onClick={() => {
                if (cardClickable) {
                  handleSelectedEmployeeId(id);
                }
              }}
              role="button"
              tabIndex={0}
            >
              {renderEmployeeOrConsultant(employeeData)}

              <div className="node-footer-container" data-nodetype={employeeData._type}>
                <div
                  className="node-footer-counters"
                  style={employeeData._type === 'DbsConsultant' ? { paddingBottom: '7px' } : {}}
                >
                  <div className="reports pills daugherty-count">
                    {employeeData.countDBSConsultants}
                  </div>
                  <div className="reports pills non-daugherty-count">
                    {employeeData.countNotDBSContractors}
                  </div>
                  <div className="reports pills direct-count">
                    {employeeData.countDirectEmployees}
                  </div>
                </div>
              </div>
              {hasChildren && !showChildren && (
                <div
                  className="node-subordinate-indicator"
                  data-nodetype={employeeData._type}
                  style={employeeData._type === 'DbsConsultant' ? { left: '50%' } : {}}
                >
                  <span className="org-nav-icon" />
                  <span className="drill-down-icon" />
                </div>
              )}
            </div>
          )}
        </>
      )}
      {hasChildren && showChildren && (
        <DaughertyNodesSubset
          directReports={directReports}
          subLevelsToShow={subLevelsToShow}
          toastMessage={toastMessage}
          handleSelectedEmployeeId={handleSelectedEmployeeId}
        />
      )}
    </div>
  );
}

const employeeData = {
  accountName: PropTypes.string,
  countDBSConsultants: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countDBSConsultantsTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countDirectEmployees: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countDirectEmployeesTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countNotDBSContractors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countNotDBSContractorsTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  department: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.string,
  lastName: PropTypes.string,
  clientManagerId: PropTypes.string,
  clientManagerName: PropTypes.string,
  relationshipStatus: PropTypes.oneOf(Object.keys(relations)),
  daughertyManagerId: PropTypes.string,
  daughertyManagerName: PropTypes.string,
  title: PropTypes.string,
  _type: PropTypes.string,
};
employeeData.children = PropTypes.arrayOf(PropTypes.shape(employeeData));
const employeeDataShape = PropTypes.shape(employeeData);

DaughertyOrgChartNode.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  department: PropTypes.string,
  directReports: PropTypes.arrayOf(employeeDataShape),
  subLevelsToShow: PropTypes.number,
  handleSelectedEmployeeId: PropTypes.func,
  employeeData: employeeDataShape,
  rootNode: PropTypes.bool,
};

DaughertyOrgChartNode.defaultProps = {
  title: '',
  department: '',
  directReports: [],
  subLevelsToShow: 0,
  handleSelectedEmployeeId: () => {},
  employeeData: null,
  rootNode: false,
};

export default DaughertyOrgChartNode;
