import React from 'react';
import { TextField, Box } from '@mui/material';
import { formatDate, parseWithLocalTimezone, dateWithoutTime } from '../utils/dates';

function DateRangePicker({ onChange, onValid, value }) {
  const [startDate, endDate] = [].concat(value);

  const handleDateRangeChange = (range) => {
    const [rangeStartDate, rangeEndDate] = range;
    onValid(dateWithoutTime(rangeStartDate) <= dateWithoutTime(rangeEndDate));
    onChange(range);
  };

  const handleStartDateChange = (event) => {
    const startDateForChange = parseWithLocalTimezone(event.target.value);
    handleDateRangeChange([startDateForChange, endDate]);
  };

  const handleEndDateChange = (event) => {
    const endDateForChange = parseWithLocalTimezone(event.target.value);
    handleDateRangeChange([startDate, endDateForChange]);
  };

  return (
    <Box display="flex">
      <Box m={1}>
        <TextField
          id="start_date"
          onChange={handleStartDateChange}
          value={formatDate(startDate)}
          label="Start Date"
          type="date"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box m={1}>
        <TextField
          id="end_date"
          onChange={handleEndDateChange}
          value={formatDate(endDate)}
          label="End Date"
          type="date"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    </Box>
  );
}

export default DateRangePicker;
