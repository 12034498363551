import React, { useEffect } from 'react';
import { useAccount } from '../contexts/AccountContext';
import LoginForm from '../components/LoginForm';

function Login({ updateUser, user }) {
  const { clearActiveAccount } = useAccount();

  useEffect(() => {
    clearActiveAccount();
  }, [clearActiveAccount]);

  return <LoginForm updateUser={updateUser} user={user} />;
}

export default Login;
