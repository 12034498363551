import apiBaseUrl from '../utils/apiBaseUrl';

export function handleLogin(username, password) {
  return fetch(`${apiBaseUrl}/login`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      username,
      password,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
}

export function handleLogout() {
  return fetch(`${apiBaseUrl}/logout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function validateToken() {
  return fetch(`${apiBaseUrl}/validate_token`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });
}
