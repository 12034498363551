import React from 'react';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../colors';

const useStyles = makeStyles({
  main: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    color: colors.error,
  },
  text: {
    textAlign: 'center',
  },
});

function ErrorCard({ lines }) {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <CardHeader component="div" title="An error has occured" />
        <CardContent>
          {lines.map((l) => (
            <Typography key={lines.indexOf(l)} variant="h3" className={classes.text}>
              {l}
            </Typography>
          ))}
        </CardContent>
        <CardHeader component="div" title="Please contact support for assistance" />
      </Card>
    </div>
  );
}

export default ErrorCard;
