import React from 'react';
import { Popper } from '@mui/material';

function SearchBarPopper(props) {
  return (
    <Popper
      {...props}
      style={{
        popper: {
          width: 'fit-content',
        },
      }}
      placement="bottom-start"
    />
  );
}

export default SearchBarPopper;
