import toastMessage from '../utils/toastMessage/toastMessageUtility';
import apiBaseUrl from '../utils/apiBaseUrl';

// eslint-disable-next-line import/prefer-default-export
export const refreshAllData = async () => {
  const startTime = Date.now();
  const ONE_MINUTE_TIMEOUT_MILISECONDS = 60000;
  await fetch(`${apiBaseUrl}/data_refresh`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.status === 200) {
        toastMessage.success('All data has been refreshed.');
      }
      return response.json();
    })
    .catch((e) => {
      if (
        Date.now() - startTime > ONE_MINUTE_TIMEOUT_MILISECONDS &&
        e.message &&
        e.message.includes('Failed to fetch')
      ) {
        toastMessage.success(
          'The data refresh is still in progress and will finish in couple minutes',
        );
      } else {
        toastMessage.error("We're having problems refreshing information");
      }
    });
};
