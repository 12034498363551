import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Paper, Box, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import downloadUsageReport from '../api/Reports';
import DateRangePicker from '../components/DateRangePicker';
import AdminUserContext from '../contexts/AdminUserContext';

const useStyles = makeStyles({
  main: {
    padding: '3rem',
  },
});

function Reports() {
  const classes = useStyles();

  const [dateRange, onChangeDateRange] = useState([new Date(), new Date()]);
  const [validDateRange, onValidDateRange] = useState(true);
  const { isAdminUser } = useContext(AdminUserContext);

  const handleDownloadClick = () => {
    const [startDate, endDate] = dateRange;
    downloadUsageReport(startDate, endDate);
  };

  return (
    <main className={classes.main}>
      {isAdminUser && (
        <>
          <Typography variant="h5">Reports</Typography>
          <Paper style={{ padding: 16, marginTop: 10 }}>
            <Typography variant="h6" gutterBottom>
              Logged in Usage Report
            </Typography>
            <Box display="flex" alignItems="center">
              <Box>
                <DateRangePicker
                  onChange={onChangeDateRange}
                  onValid={onValidDateRange}
                  value={dateRange}
                />
              </Box>
              <Box ml={2}>
                <Button
                  onClick={handleDownloadClick}
                  variant="contained"
                  disabled={!validDateRange}
                  color="primary"
                >
                  Download Report
                </Button>
              </Box>
            </Box>
          </Paper>
        </>
      )}
    </main>
  );
}

export default withRouter(Reports);
