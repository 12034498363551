const views = Object.freeze({
  CLIENT_VIEW: {
    fullName: 'Client Org View',
    shortName: 'Client View',
    dataTestId: 'client-view',
    personType: 'employee',
    viewName: 'client',
  },
  DAUGHERTY_VIEW: {
    fullName: 'Daugherty Team View',
    shortName: 'Daugherty View',
    dataTestId: 'daugherty-view',
    personType: 'consultant',
    viewName: 'daugherty',
  },
});

function getByPersonType(personType) {
  return personType === 'consultant' ? views.DAUGHERTY_VIEW : views.CLIENT_VIEW;
}

function getByViewName(viewName) {
  return viewName === views.DAUGHERTY_VIEW.viewName ? views.DAUGHERTY_VIEW : views.CLIENT_VIEW;
}

export default { views, getByPersonType, getByViewName };
