import React, { useState, useEffect, useMemo } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import './App.css';
import './App.scss';
import { alpha } from '@mui/material/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IdleTimer from 'react-idle-timer';
import theme from './muiTheme';
import colors from './colors';
import Home from './pages/Home/Home';
import Login from './pages/Login';
import Reports from './pages/Reports';
import AppBar from './components/AppBar';
import Loading from './components/Loading';
import AccountDetails from './pages/AccountDetails/AccountDetails';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import OrgChart from './pages/OrgChart/OrgChart';
import ErrorCard from './components/ErrorCard';
import ToastMessageContainer from './utils/toastMessage/toastMessageContainer';
import SearchContext from './contexts/SearchContext';
import AdminUserContext from './contexts/AdminUserContext';
import { AccountProvider } from './contexts/AccountContext';
import { handleLogout, validateToken } from './api/Authentication';
import { checkAdminUser } from './api/AdminUsers';
import Settings from './pages/Settings';
import IdleTimeoutModal from './components/IdleTimeoutModal';
import searchViews from './utils/searchViews';
import Footer from './components/Footer';

const drawerWidth = 400;

const styles = {
  main: {
    backgroundColor: colors.shaded,
  },
  headerText: {
    fontSize: 20,
    fontWeight: 700,
    cursor: 'pointer',
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  root: {
    display: 'flex',
  },
  appBarItemsContainer: {
    width: '100%',
  },
  appBar: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '75px',
    overflow: 'hidden',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    padding: '0px',
    width: '100%',
    overflow: 'hidden',
  },
  searchBar: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(colors.darkGrey, 0.15),
    '&:hover': {
      backgroundColor: alpha(colors.shaded, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInput: {
    padding: theme.spacing(1, 1, 1, 1),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`, // padding between icon and search field
    width: '100%',
  },
  searchInputRoot: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  adminContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

function App() {
  const useStyles = makeStyles(() => styles);
  const [setupComplete, setSetupComplete] = useState(false);
  const [user, setUser] = useState({ username: '', isLoggedIn: false });
  const [subLevelsToShow, setSubLevelsToShow] = useState(1);
  const [search, setSearch] = useState(null); // tracks the search value submitted by the user
  const [currentView, setCurrentView] = useState(searchViews.views.CLIENT_VIEW);
  const [account] = useState(''); // tracks the account being looked at
  const [displayData, setDisplayData] = useState(false); // tells app to display something other than the search prompt
  // meant to track every time the user submits a search, even if the value is the same
  const [newSearchSubmitted, setNewSearchSubmitted] = useState(false);
  const [isLoadingContacts, setLoadingContacts] = useState(false);
  // Set enhance roles
  const [isAdminUser, setIsAdminUser] = useState(undefined);
  const [isClientUser, setIsClientUser] = useState(undefined);
  const [isTeamUser, setIsTeamUser] = useState(undefined);

  const [currentEmployee, setCurrentEmployee] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const locCurrent = useLocation();
  const locHistory = useHistory();

  const handleUserChange = (newUser) => {
    setUser(newUser);
  };

  const handleOnIdle = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    handleLogout().finally(() => {
      handleUserChange({
        username: '',
        isLoggedIn: false,
      });
    });
  };

  useEffect(() => {
    let isSubscribed = true;
    setSetupComplete(false);
    validateToken()
      .then((response) => response.json())
      .then((validTokenResult) => {
        if (isSubscribed && validTokenResult.username) {
          setUser({
            username: validTokenResult.username,
            isLoggedIn: true,
          });
        }
      })
      .finally(() => {
        if (isSubscribed) {
          setSetupComplete(true);
        }
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (user && user.username) {
      checkAdminUser().then(({ isAdmin, isClient, isTeam }) => {
        if (isSubscribed) {
          setIsAdminUser(isAdmin);
          setIsClientUser(isClient);
          setIsTeamUser(isTeam);
        }
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [user]);

  useEffect(() => {
    const currentPath = locCurrent.pathname;
    if (
      (currentPath === '/settings/refresh' && typeof isAdminUser !== 'undefined' && !isAdminUser) ||
      (currentPath === '/settings/users' && typeof isAdminUser !== 'undefined' && !isAdminUser) ||
      (currentPath === '/settings/organization' &&
        typeof isClientUser !== 'undefined' &&
        !isClientUser) ||
      (currentPath === '/reports' && typeof isAdminUser !== 'undefined' && !isAdminUser)
    ) {
      locHistory.push('/');
    }
  }, [locCurrent, isAdminUser, locHistory, isClientUser]);

  const classes = useStyles();
  const minute = 60 * 1000;
  const tenMinutes = minute * 10;

  const adminUserContext = useMemo(
    () => ({ isAdminUser, setIsAdminUser, user, isClientUser, isTeamUser }),
    [isAdminUser, user, isClientUser, isTeamUser],
  );

  const searchContext = useMemo(
    () => ({
      account,
      search,
      setSearch,
      displayData,
      setDisplayData,
      newSearchSubmitted,
      setNewSearchSubmitted,
      currentEmployee,
      setCurrentEmployee,
      currentView,
      setCurrentView,
    }),
    [account, displayData, currentEmployee, newSearchSubmitted, search, currentView],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AdminUserContext.Provider value={adminUserContext}>
          <SearchContext.Provider value={searchContext}>
            <AccountProvider>
              <div>
                <ToastMessageContainer />
                <AppBar
                  user={user}
                  updateUser={handleUserChange}
                  styles={classes}
                  setLoadingContacts={setLoadingContacts}
                  isLoadingContacts={isLoadingContacts}
                />
                {user.isLoggedIn && (
                  <>
                    <IdleTimer onIdle={handleOnIdle} timeout={tenMinutes} />
                    <IdleTimeoutModal
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      closeModal={closeModal}
                    />
                  </>
                )}

                {setupComplete && isLoadingContacts ? (
                  <Loading displayText="Data Sync in Progress" />
                ) : (
                  ''
                )}
                {setupComplete && (
                  <Switch>
                    <Route
                      path="/hierarchy/:routePersonId/:routeAccount/:routeView"
                      exact
                      render={() =>
                        user.isLoggedIn ? <OrgChart styles={classes} /> : <Redirect to="/login" />
                      }
                    />
                    <Route
                      path="/"
                      exact
                      render={() =>
                        user.isLoggedIn ? <Home user={user} /> : <Redirect to="/login" />
                      }
                    />
                    <Route // TODO: Need to make the account page actually useful.
                      path="/account/:accountId/:routeTab/:routeParentId?/:personType?"
                      exact
                      render={({ history }) =>
                        user.isLoggedIn ? (
                          <AccountDetails
                            history={history}
                            styles={classes}
                            subLevelsToShow={subLevelsToShow}
                            setSubLevelsToShow={setSubLevelsToShow}
                          />
                        ) : (
                          <Redirect to="/login" />
                        )
                      }
                    />
                    <Route
                      path="/hierarchy/:routePersonId/:routeAccount"
                      exact
                      render={({ history }) =>
                        user.isLoggedIn ? (
                          <AccountDetails
                            history={history}
                            styles={classes}
                            subLevelsToShow={subLevelsToShow}
                            setSubLevelsToShow={setSubLevelsToShow}
                          />
                        ) : (
                          <Redirect to="/login" />
                        )
                      }
                    />
                    <Route
                      path="/reports"
                      render={(props) =>
                        user.isLoggedIn ? <Reports {...props} /> : <Redirect to="/" />
                      }
                    />
                    <Route
                      path="/settings"
                      render={(location) =>
                        user.isLoggedIn ? (
                          <Settings
                            styles={classes}
                            subLevelsToShow={subLevelsToShow}
                            setSubLevelsToShow={setSubLevelsToShow}
                            setLoadingContacts={setLoadingContacts}
                            location={location}
                          />
                        ) : (
                          // ToDo: Should we redirect else where and drop a toast
                          <Redirect to="/login" />
                        )
                      }
                    />
                    <Route
                      path="/login"
                      render={() =>
                        !user.isLoggedIn ? (
                          <Login user={user} updateUser={handleUserChange} />
                        ) : (
                          <Redirect to="/" />
                        )
                      }
                    />
                    <Route
                      path="/*"
                      render={() => (
                        <ErrorCard lines={['The page you are looking for does not exist']} />
                      )}
                    />
                  </Switch>
                )}
                {user.isLoggedIn && <Footer user={user} />}
              </div>
            </AccountProvider>
          </SearchContext.Provider>
        </AdminUserContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
