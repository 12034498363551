import React from 'react';
import { Link } from 'react-router-dom';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({
  card: {
    display: 'inline-block',
    textAlign: 'center',
    width: 120,
    height: 120,
    webkitBorderRadius: '20%',
    mozBorderRadius: '20%',
    borderRadius: '20%',
    webkitBoxShadow: '0px 3.5px 3.5px rgba(0,0,0,0.8)',
    mozBoxShadow: '0px 3.5px 3.5px rgba(0,0,0,0.8)',
    boxShadow: 'rgba(0,0,0,0.45) 0px 2px 5px 0px',
    justifyContent: 'center',
    overflow: 'hidden',
    alignItems: 'center',
    border: 0,
    margin: '3px',
  },
  actionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardImage: {
    marginTop: 3,
    maxWidth: 120,
    maxHeight: 120,
  },
  image: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function AccountCard({ account, setActiveAccount }) {
  const classes = useStyles();

  const displayCardImage = () => {
    if (account.logoURL === null) return <h3>{account.name}</h3>;
    return <img src={account.logoURL} className={classes.cardImage} alt={`${account.name} logo`} />;
  };

  return (
    <ImageListItem>
      <Tooltip title={account.name} arrow>
        <Link
          name="client-link"
          to={{
            pathname: `/account/${account.name}/org`,
          }}
        >
          <Card
            id={`account-card-${account.name}`}
            className={classes.card}
            onClick={() => {
              setActiveAccount(account);
            }}
          >
            <CardActionArea className={classes.actionArea}>
              <div className={classes.image}>
                <CardContent className={classes.cardContent}>
                  {displayCardImage(account)}
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        </Link>
      </Tooltip>
    </ImageListItem>
  );
}

export default AccountCard;
