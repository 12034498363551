import colors from '../colors';

export default {
  expandIcon: {
    marginLeft: '10px',
    marginTop: '10px',
    height: '20px',
    backgroundColor: colors.shaded,
    borderRadius: '4px',
  },
};
