import axios from 'axios';
import { transformDataByEmail, appendRole } from '../utils/roleHelper';
import toastMessage from '../utils/toastMessage/toastMessageUtility';
import apiBaseUrl from '../utils/apiBaseUrl';

export const getAdminUsers = async () => {
  let adminUsers = [];
  let originalUsers = [];
  let error = false;

  await axios
    .get(`${apiBaseUrl}/admin-users`, { withCredentials: true })
    .then((json) => {
      originalUsers = appendRole(json.data);
      adminUsers = transformDataByEmail(json.data);
    })
    .catch(() => {
      toastMessage.error('An error has occurred fetching admin user information');
      error = true;
    });

  return error
    ? null
    : {
        originalUsers,
        adminUsers: adminUsers.map((user) => ({
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          upn: user.upn,
          isEditing: false,
          admin: user.admin,
          team: user.team,
          client: user.client,
        })),
      };
};

export const deleteAdminUser = async (id) => {
  let status = {};

  await axios
    .delete(`${apiBaseUrl}/delete-admin-user/${id}`, { withCredentials: true })
    .then((json) => {
      status = json.data;
    })
    .catch((e) => {
      status = { deleteStatus: 'fail', id, error: e };
    });

  return status;
};

export const checkAdminUser = async () => {
  let isAdmin = false;
  let isClient = false;
  let isTeam = false;

  await axios
    .get(`${apiBaseUrl}/is-admin-user`, { withCredentials: true })
    .then((json) => {
      isAdmin = !!json.data.isAdmin.find((role) => role === 'Admin');
      isClient = !!json.data.isAdmin.find((role) => role === 'Client');
      isTeam = !!json.data.isAdmin.find((role) => role === 'Team');
    })
    .catch(() => {
      toastMessage.error('An error has occurred fetching admin user information');
    });

  return { isAdmin, isClient, isTeam };
};

export const addAdminUser = async (user) => {
  let response;
  await axios
    .post(
      `${apiBaseUrl}/add-admin-user`,
      {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        upn: user.upn,
      },
      { withCredentials: true },
    )
    .then((newUser) => {
      response = newUser;
    })
    .catch((e) => {
      response = e.response;
    });
  return response;
};

export const addUserByRole = async (user, role) => {
  let response;
  await axios
    .post(
      `${apiBaseUrl}/add-${role}-user`,
      {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        upn: user.upn,
      },
      { withCredentials: true },
    )
    .then((newUser) => {
      response = newUser;
    })
    .catch((e) => {
      response = e.response;
    });
  return response;
};

export const editAdminUser = async (user) => {
  let response;

  await axios
    .post(`${apiBaseUrl}/update-admin-user`, user, { withCredentials: true })
    .then((r) => {
      response = r;
    })
    .catch((e) => {
      response = e.response;
    });

  return response;
};
