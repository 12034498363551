import makeStyles from '@mui/styles/makeStyles';
import iconDrillUp from '../images/icon_drill_up.png';

export default makeStyles({
  navigateUpIcon: {
    display: 'flex',
    width: '20px',
    height: '10px',
    marginBottom: '4px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundImage: `url(${iconDrillUp})`,
  },
  navigateUpIcons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    bottom: -10,
    zIndex: '1',
  },
});
