import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// This should only be included in the application one time, otherwise duplicate toast messages appear
function ToastMessageContainer() {
  return <ToastContainer />;
}

export default ToastMessageContainer;
