class Breadcrumb {
  constructor(employeeData) {
    this.name = `${employeeData.firstName} ${employeeData.lastName}`;
    this.parentId = employeeData.parentId;
    this.id = employeeData.id;
  }

  equals(other) {
    return other && this.id === other.id;
  }
}

export default Breadcrumb;
