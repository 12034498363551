import React, { useContext } from 'react';
import { AppBar, Toolbar } from '@mui/material/';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Tooltip from '@mui/material/Tooltip';
import { withRouter } from 'react-router-dom';
import makeStyles from './AppBar.styles';
import { accountRefresh } from '../api/Accounts';
import { handleLogout } from '../api/Authentication';
import { useAccount } from '../contexts/AccountContext';
import AdminUserContext from '../contexts/AdminUserContext';
import toastMessage from '../utils/toastMessage/toastMessageUtility';
import SearchBar from './SearchBar';
import colors from '../colors';

function Nav({ updateUser, styles, history, user, isLoadingContacts, setLoadingContacts }) {
  const buttonStyle = makeStyles();
  const { isAdminUser, isClientUser, isTeamUser } = useContext(AdminUserContext);
  const { activeAccount, updateAccount } = useAccount();

  const checkEnhancedRole = () => {
    return isAdminUser || isClientUser || isTeamUser;
  };

  const handleRefresh = async () => {
    setLoadingContacts(true);
    const startTime = Date.now();
    const ONE_MINUTE_TIMEOUT_MILISECONDS = 60000;
    accountRefresh(activeAccount.name)
      .then((r) => {
        if (r.statusCode === 200) {
          toastMessage.success(
            `${activeAccount.name} data has been refreshed.`,
            Date.now - startTime,
          );
          updateAccount({
            ...activeAccount,
            lastRefreshMs: r.lastRefreshMs,
          });
        } else {
          toastMessage.error("We're having problems refreshing information");
        }
      })
      .catch((e) => {
        if (
          Date.now() - startTime > ONE_MINUTE_TIMEOUT_MILISECONDS &&
          e.message &&
          e.message.includes('Failed to fetch')
        ) {
          toastMessage.success(
            'The data refresh is still in progress and will finish in about a minute',
          );
        } else {
          toastMessage.error("We're having problems refreshing information");
        }
      })
      .finally(() => {
        setLoadingContacts(false);
      });
  };

  const handleLogOut = async () => {
    handleLogout().finally(() => {
      updateUser({
        username: '',
        isLoggedIn: false,
      });
      history.push('/');
    });
  };

  const handleOnClickHome = () => {
    localStorage.setItem('UserLastLoc', '/');
    history.push('/');
  };

  const handleOnClickReports = () => {
    history.push('/reports');
  };

  const handleOnClickSettings = () => {
    const page = isAdminUser ? 'refresh' : 'organization';
    history.push(`/settings/${page}`);
  };

  const clickOnLogoHandler = () => {
    history.push('/');
  };

  return (
    <AppBar color="inherit" position="sticky" className={styles.appBar}>
      <div className={styles.appBarItemsContainer}>
        <CssBaseline />
        <Toolbar>
          <div
            role="button"
            onClick={clickOnLogoHandler}
            style={{ marginTop: '6px', padding: 0, cursor: 'pointer' }}
            tabIndex={0}
          >
            <img style={{ maxWidth: 275, flex: 1 }} src="/images/logo.png" alt="Daugherty logo" />
          </div>
          <div className={styles.toolbarButtons}>
            {user.isLoggedIn && (
              <Tooltip title="Home" arrow>
                <Button
                  data-testid="home-button"
                  className={styles.headerText}
                  onClick={() => handleOnClickHome()}
                >
                  <HomeIcon title="Home" className={buttonStyle.iconButton} />
                </Button>
              </Tooltip>
            )}
            {user.isLoggedIn && activeAccount.name && (
              <Tooltip title={!isLoadingContacts ? 'Refresh Data' : 'Data is Refreshing'} arrow>
                <Button
                  style={isLoadingContacts ? { cursor: 'not-allowed' } : {}}
                  data-testid="Refresh"
                  className={styles.headerText}
                  onClick={() => (!isLoadingContacts ? handleRefresh() : {})}
                >
                  <CachedIcon
                    className={buttonStyle.iconButton}
                    style={isLoadingContacts ? { color: colors.darkGrey } : {}}
                  />
                </Button>
              </Tooltip>
            )}
            {user.isLoggedIn && isAdminUser && (
              <Tooltip title="Reports" arrow>
                <Button
                  data-testid="reports-button"
                  className={styles.headerText}
                  onClick={() => handleOnClickReports()}
                >
                  <FeedOutlinedIcon className={buttonStyle.iconButton} />
                </Button>
              </Tooltip>
            )}
            {user.isLoggedIn && checkEnhancedRole() && (
              <Tooltip title="Settings" arrow>
                <Button
                  data-testid="settings-button"
                  className={styles.headerText}
                  onClick={() => handleOnClickSettings()}
                >
                  <SettingsIcon className={buttonStyle.iconButton} />
                </Button>
              </Tooltip>
            )}
            {user.isLoggedIn && (
              <Tooltip title="Sign Out" arrow>
                <Button
                  data-testid="sign-out-button"
                  className={styles.headerText}
                  onClick={() => handleLogOut()}
                >
                  <LogoutIcon className={buttonStyle.iconButton} />
                </Button>
              </Tooltip>
            )}
          </div>
          {user.isLoggedIn && (
            <SearchBar styles={styles} activeAccount={activeAccount} history={history} />
          )}
        </Toolbar>
      </div>
    </AppBar>
  );
}

export default withRouter(Nav);
