// relations utility to make coding easier and keep things consistant
// The key of each object should match the string we receive from SalesForce
// if Salesforce ever changes the string, it will need to be updated here only

export default {
  'Trusted Advisor': {
    css: 'RelationshipLightGreen',
    color: '#b3e06e',
    label: 'Trusted Advisor',
  },
  Favorable: {
    css: 'RelationshipLime',
    color: '#9eb7dc',
    label: 'Favorable',
  },
  Neutral: {
    css: 'RelationshipYellow',
    color: '#dedede',
    label: 'Neutral',
  },
  Target: {
    css: 'RelationshipBlue',
    color: '#c5bedd',
    label: 'Target',
  },
  Unfavorable: {
    css: 'RelationshipRed',
    color: '#f6c76f',
    label: 'Unfavorable',
  },
};
