/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import * as d3 from 'd3';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import OrgChartComponent from '../../components/OrgChartComponent';

import componentStyles from './OrgChart.styles';
import DaughertyLegends from '../../components/DaughertyLegends/DaughertyLegends';
import Loading from '../../components/Loading';
import Logo from '../../components/Logo';
import Legends from '../../components/Legends';
import SearchPrompt from '../../components/SearchPrompt';
import SearchNotFound from '../../components/SearchNotFound';
import SearchContext from '../../contexts/SearchContext';
import { getEmployeeDataById } from '../../api/Employees';
import LastRefreshFooter from '../../components/LastRefreshFooter';
import { useAccount } from '../../contexts/AccountContext';
import searchViews from '../../utils/searchViews';
import apiBaseUrl from '../../utils/apiBaseUrl';

// TODO: add propTypes
function OrgChart({ styles }) {
  const [data, setData] = useState(null);

  const { activeAccount, setAccountByName, isLoadingAccounts } = useAccount();
  const { routePersonId, routeAccount, routeView, accountId } = useParams();
  const currentUrl = useLocation();
  const [parentId, setParentId] = useState('');
  const [validContact, setValidContact] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [includeConsultants, setIncludeConsltants] = useState(true);
  const {
    search,
    displayData,
    setDisplayData,
    newSearchSubmitted,
    setNewSearchSubmitted,
    setCurrentEmployee,
    currentEmployee,
    currentView,
    setCurrentView,
  } = useContext(SearchContext);
  const [newPersonIdSubmitted, setNewPersonIdSubmitted] = useState(false);
  const classes = componentStyles();
  useEffect(() => {
    if (routeView === 'daugherty') {
      setCurrentView(searchViews.views.DAUGHERTY_VIEW);
    } else {
      setCurrentView(searchViews.views.CLIENT_VIEW);
    }
  }, [setCurrentView, routeView]);

  const handleSelectedEmployeeId = useCallback(
    (empId, switchToClientView = false) => {
      if (switchToClientView) {
        setNewSearchSubmitted(true);
      }
      setParentId(empId);
      setNewPersonIdSubmitted(true);
    },
    [setNewSearchSubmitted],
  );

  useEffect(() => {
    let isSubscribed = true;
    if (routeAccount || accountId) {
      setAccountByName(routeAccount || accountId, isSubscribed);
    }
    return () => {
      isSubscribed = false;
    };
  }, [routeAccount, accountId, setAccountByName]);

  useEffect(() => {
    if (routePersonId) {
      setParentId(routePersonId);
    }
  }, [routePersonId, routeView, setCurrentView]);

  useEffect(() => {
    if (parentId || (currentEmployee && currentEmployee.id)) {
      handleSelectedEmployeeId(parentId || currentEmployee.id);
    }
  }, [parentId, currentEmployee, handleSelectedEmployeeId]);

  useEffect(() => {
    if (currentEmployee) {
      setEmployeeData([currentEmployee]);
      setDisplayData(true);
      setParentId(currentEmployee.id);
    }

    return () => {
      setCurrentEmployee(null);
    };
  }, [currentEmployee, setCurrentEmployee, setDisplayData]);

  useEffect(() => {
    if (newPersonIdSubmitted && activeAccount.name) {
      // mimicks approach to newSearchSubmitted
      setNewPersonIdSubmitted(false);
      setIsLoadingData(true);

      getEmployeeDataById(activeAccount, parentId, currentView)
        .then((result) => {
          if (result) {
            const newEmployee = result.at(0);
            setEmployeeData([newEmployee]);
            // const newUrl = `/foo/${newEmployee.id}/${activeAccount.name}/${currentView.viewName}`;
            // if (history.location.pathname !== newUrl) {
            //  history.push(newUrl);
            // }
            setValidContact(true);
            setDisplayData(true);
          } else {
            setValidContact(false);
          }
        })
        .finally(() => {
          setIsLoadingData(false);
        });
    }
  }, [
    activeAccount,
    parentId,
    newPersonIdSubmitted,
    setNewPersonIdSubmitted,
    employeeData,
    currentView,
    newSearchSubmitted,
    setDisplayData,
  ]);

  // TODO: Restore client/Daugherty view, render without pruning URL
  const getTestCsv = async (view, showConsultants) => {
    const currentPath = `${window.location.pathname
      .replace(`/client`, ``)
      .replace(`/daugherty`, ``)}?relationship_view=${view}`.concat(
      `&exclude_consultants=${!showConsultants}`,
    );
    const workingCsv = await fetch(`${apiBaseUrl}${currentPath}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const testCsv = await d3.csvParse(await workingCsv.text());
    return testCsv;
  };

  // Renders on URL change
  useEffect(() => {
    getTestCsv(routeView, includeConsultants).then((result) => {
      if (result) {
        setData(result);
      }
    });
  }, [currentUrl, routeView, includeConsultants]);

  if (isLoadingData || isLoadingAccounts) return <Loading displayText="Loading" />;
  if (!validContact && displayData)
    return <SearchNotFound searchTerm={search} account={activeAccount.name} />;

  return (
    <div className={styles.root}>
      <main className={styles.content}>
        <div className={classes.contentContainer}>
          <div className={classes.headersBox}>
            <Logo account={activeAccount} />
            <h3>{currentView.fullName}</h3>
            <FormControlLabel
              control={
                <Switch
                  {...'Show Consultants'}
                  disabled={currentView === searchViews.views.DAUGHERTY_VIEW}
                  color="primary"
                  checked={includeConsultants || currentView === searchViews.views.DAUGHERTY_VIEW}
                  onChange={(event) => {
                    setIncludeConsltants(event.target.checked);
                  }}
                />
              }
              label="Include Consultants"
            />
          </div>
          {displayData && employeeData.length > 0 && (
            <>
              <OrgChartComponent data={data} />
              {currentView === searchViews.views.CLIENT_VIEW && <Legends />}
              {currentView === searchViews.views.DAUGHERTY_VIEW && <DaughertyLegends />}
            </>
          )}

          {!displayData && <SearchPrompt />}
        </div>
        <LastRefreshFooter activeAccount={activeAccount} />
      </main>
    </div>
  );
}

export default withRouter(OrgChart);
