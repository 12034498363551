export default {
  primary: '#548c3f',
  secondary: '#215593',
  shaded: '#e4e4e4',
  background: '#f2f2f2',
  darkGrey: '#919191',
  error: '#e03157',
  warning: '#FC9824',
  info: '#3097EF',
  white: '#ffffff',
  daugherty: '#b3e06e',
  $daughertyDarker: '#548c3f',
  nonDaugherty: '#4c70ae',
  directEmployee: '#737373',
  black: 'rgba(0, 0, 0, 0.87)',
};
