import React from 'react';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../colors';

const useStyles = makeStyles({
  main: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    width: '530px',
    maxHeight: 'calc(100% - 64px)',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    color: colors.error,
  },
  text: {
    textAlign: 'center',
  },
});

function SearchNotFound({ searchTerm, account }) {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <CardHeader component="div" title="Contact Not Found" />
        <CardContent>
          <Typography variant="h5" className={classes.text}>
            {`"${searchTerm}" did not match any contacts at ${account}`}
          </Typography>
        </CardContent>
        <CardHeader component="div" title="Please Try Again" />
      </Card>
    </div>
  );
}

export default SearchNotFound;
