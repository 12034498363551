import apiBaseUrl from '../utils/apiBaseUrl';

const sendFeedbackMessage = (email, message) => {
  return fetch(`${apiBaseUrl}/feedback`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      email,
      message,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
};

export default sendFeedbackMessage;
