import React from 'react';
import relations from '../utils/relations';

// loop through the relations from the utility to build the legend

function RelationshipLegend({ classes }) {
  return (
    <div className={classes.innerLegend}>
      {Object.values(relations).map((r) => (
        <div key={r.color} className={classes.keybox} style={{ backgroundColor: r.color }}>
          {r.label}
        </div>
      ))}
    </div>
  );
}

export default RelationshipLegend;
