import React from 'react';
import { InputLabel, Box, MenuItem, Select } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  iconLogo: {
    width: 32,
    marginRight: 10,
  },
  accountPicker: {
    width: 250,
  },
});

function AccountPicker({ accounts, selected, onChange }) {
  const classes = useStyles();

  const handleAccountChange = (e) => {
    onChange(accounts.find((x) => x.id === e.target.value));
  };

  return (
    <Box pt={2}>
      <InputLabel>Select Organization</InputLabel>
      <Select
        id="account-picker"
        displayEmpty
        onChange={handleAccountChange}
        className={classes.accountPicker}
        value={selected ? selected.id : ''}
      >
        <MenuItem value="" disabled>
          Select
        </MenuItem>
        {accounts.map((account) => (
          <MenuItem key={account.id} value={account.id}>
            {account.logoURL && <img className={classes.iconLogo} src={account.logoURL} alt="" />}
            {account.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default AccountPicker;
