import React, { useState, useEffect, useContext } from 'react';
import { Button, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AdminUserContext from '../contexts/AdminUserContext';
import AdminUsersDataGrid from './AdminUsersDataGrid';
import AlertDialog from './AlertDialog';
import { getAdminUsers, editAdminUser, deleteAdminUser, addUserByRole } from '../api/AdminUsers';
import { isValidUser } from '../utils/userValidation';
import toastMessage from '../utils/toastMessage/toastMessageUtility';
import colors from '../colors';
import {
  filterDataToUpdateByRoles,
  convertOriginalToSingle,
  getPreviousUpnForDelete,
  getUserRoleIds,
} from '../utils/roleHelper';

const useStyles = makeStyles({
  headerText: {
    fontSize: '26px',
    fontWeight: 'normal',
    cursor: 'pointer',
    lineHeight: '35px',
    color: colors.secondary,
  },
  instructions: {
    marginBottom: '1em',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'column wrap',
    overflowY: 'auto',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  faColor: {
    color: '#fff',
    zIndex: '25',
    marginRight: '.5em',
  },
  gridRowLayout: {
    overflow: 'auto',
  },
  gridDisabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
});

function AdminUserDetails() {
  const classes = useStyles();
  const [adminUsers, setAdminUsers] = useState();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedUserForDelete, setSelectedUserForDelete] = useState();
  const { user } = useContext(AdminUserContext);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  // const [requestsLeft, setRequestsLeft] = useState(0);

  const getAdmins = (successMessage) => {
    getAdminUsers().then((data) => {
      setOriginalUsers(data.originalUsers);
      setAdminUsers(data.adminUsers);
      setRequestInProgress(false);
      if (successMessage) {
        toastMessage.success(successMessage);
      }
    });
  };

  useEffect(() => {
    setRequestInProgress(true);
    getAdmins();
  }, []);

  const addUser = () => {
    if (adminUsers[0].id !== '') {
      setAdminUsers([
        {
          id: '',
          email: '',
          firstName: '',
          lastName: '',
          upn: '',
          isEditing: true,
        },
        ...adminUsers,
      ]);
    }
  };

  const anyRowIsCurrentlyEditing = () => adminUsers.filter(() => user.isEditing).length > 0;

  const setFocusedCell = (row, gridApi) => {
    gridApi.setFocusedCell(row, 'email');
    gridApi.startEditingCell({
      rowIndex: row,
      colKey: 'email',
    });
  };

  const refreshCells = (rowNode, gridApi) => {
    const rowNodes = [rowNode]; // params needs an array
    const params = {
      force: true,
      suppressFlash: true,
      rowNodes,
    };
    gridApi.refreshCells(params);
  };

  const refreshRow = (rowNode, gridApi) => {
    gridApi.redrawRows({ rowNodes: [rowNode] });
  };

  const checkIfSelf = (deletedUser) =>
    user.username.toLowerCase() === deletedUser.email.toLowerCase();

  const handleDeleteUser = (deletedUser) => {
    if (!checkIfSelf(deletedUser)) {
      setConfirmOpen(true);
      setSelectedUserForDelete(deletedUser);
    } else {
      toastMessage.error('You cannot remove yourself as admin user.');
    }
  };

  const deleteAdmin = (upn, usersUpdateSuccessMessage) => {
    const userRoleIds = getUserRoleIds(originalUsers, upn);
    setRequestInProgress(true);
    let requestsLeft = userRoleIds.length;
    userRoleIds.forEach((eachUserRoleId) => {
      deleteAdminUser(eachUserRoleId).then((status) => {
        if (status.deleteStatus === 'success') {
          requestsLeft -= 1;
          if (requestsLeft === 0) {
            getAdmins(
              usersUpdateSuccessMessage ||
                `${selectedUserForDelete.firstName} ${selectedUserForDelete.lastName} was successfully removed`,
            );
          }
        } else {
          toastMessage.error(
            `Unable to delete ${selectedUserForDelete.firstName} ${selectedUserForDelete.lastName}`,
          );
          setRequestInProgress(false);
        }
      });
    });
  };

  const editUser = (params) => {
    if (!anyRowIsCurrentlyEditing()) {
      const index = adminUsers.findIndex((x) => x.id === params.node.data.id);
      adminUsers[index].isEditing = true;
      setFocusedCell(params.node.rowIndex, params.api);
      refreshCells(params.node, params.api);
    }
  };

  const cancelEditing = (params) => {
    params.api.stopEditing(true);
    // eslint-disable-next-line no-param-reassign
    params.data.isEditing = false;
    if (params.data.id === '') {
      setAdminUsers(adminUsers.slice(1));
    }
    refreshRow(params.node, params.api);
  };

  const addNewUserWithRoles = (params) => {
    let newUser = params.data;

    const userErrors = isValidUser(params, adminUsers, true);
    if (userErrors.length === 0) {
      const requestRoles = [];
      if (params.data.admin) {
        requestRoles.push('admin');
      }
      if (params.data.client) {
        requestRoles.push('client');
      }
      if (params.data.team) {
        requestRoles.push('team');
      }
      let requestsLeft = requestRoles.length;
      setRequestInProgress(true);
      requestRoles.forEach((eachRequestRole) => {
        addUserByRole(newUser, eachRequestRole).then((response) => {
          if (response.status === 201) {
            [newUser] = response.data;
            requestsLeft -= 1;
            if (requestsLeft === 0) {
              getAdmins(
                `${newUser.firstName} ${newUser.lastName} was successfully added as an ${eachRequestRole}`,
              );
            }
          } else {
            toastMessage.error(
              `There was a problem adding ${newUser.firstName} ${
                newUser.lastName
              }: ${response.data.errors.join(', ')}.`,
            );
            setFocusedCell(0, params.api);
            setRequestInProgress(false);
          }
        });
      });
    } else {
      toastMessage.error(`Please enter a valid value in each field: ${userErrors.join(', ')}.`);
      setFocusedCell(0, params.api);
    }
  };

  const editUserOnServer = (params, payload, previousUpnToDelete) => {
    const userErrors = isValidUser(params, adminUsers);
    if (userErrors.length === 0) {
      const editServerUser = params.data;
      editServerUser.rolesPayload = payload;
      setRequestInProgress(true);
      adminUsers[params.node.rowIndex].isEditing = false;
      refreshRow(params.node, params.api);
      editAdminUser(editServerUser).then((response) => {
        const successMessage = `Changes to ${editServerUser.firstName} ${editServerUser.lastName} has been saved.`;
        if (response.status === 200) {
          if (previousUpnToDelete) {
            deleteAdmin(previousUpnToDelete, successMessage, params);
          } else {
            getAdmins(successMessage);
          }
        } else {
          toastMessage.error(
            `There was a problem editing ${editServerUser.firstName} ${
              editServerUser.lastName
            }: ${response.data.errors.join(', ')}.`,
          );
          setFocusedCell(params.node.rowIndex, params.api);
        }
      });
    } else {
      toastMessage.error(`Please enter a valid value in each field: ${userErrors.join(', ')}.`);
      setFocusedCell(params.node.rowIndex, params.api);
    }
  };

  const finishEditingUser = (params) => {
    params.api.stopEditing(false);

    if (params.data.id === '') {
      addNewUserWithRoles(params);
    } else {
      const originalUsersWithId = originalUsers
        .flat()
        .filter((item) => item.upn === params.data.upn);

      const toUpdate = [params.data];
      const original = convertOriginalToSingle(originalUsersWithId);
      const rolesPayload = filterDataToUpdateByRoles(original, toUpdate, originalUsersWithId);
      const originalUpn = getPreviousUpnForDelete(adminUsers, originalUsers);

      // need to covert toUpdate array back to an object, to make it easier for api processing
      const payload = toUpdate[0];
      payload.rolesPayload = rolesPayload;
      editUserOnServer(params, rolesPayload, originalUpn);
    }
  };

  return (
    <main className={classes.container}>
      <div className={classes.titleRow}>
        <h3 className={classes.headerText}>User Roles</h3>
        <Button
          data-testid="add-user-button"
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon className={classes.faColor} icon={faPlus} />}
          onClick={() => {
            addUser();
          }}
        >
          Add User
        </Button>
      </div>
      <div className={classes.instructions}>
        Add or remove admin permissions from the employee list below
      </div>
      <div className={classes.gridRowLayout}>
        {!adminUsers ? (
          <LinearProgress />
        ) : (
          <div className={requestInProgress ? classes.gridDisabled : undefined}>
            <AdminUsersDataGrid
              jsonRowData={adminUsers}
              editUser={editUser}
              finishEditingUser={finishEditingUser}
              cancelEditing={cancelEditing}
              handleDeleteUser={handleDeleteUser}
              requestInProgress={requestInProgress}
              currentUserEmail={user.username}
            />

            {selectedUserForDelete && (
              <AlertDialog
                title="Remove Admin"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => deleteAdmin(selectedUserForDelete.upn)}
              >
                {`You are about to remove admin privileges from ${selectedUserForDelete.firstName} ${selectedUserForDelete.lastName}.
               Would you like to proceed with this change?`}
              </AlertDialog>
            )}
          </div>
        )}
      </div>
    </main>
  );
}

export default AdminUserDetails;
