import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Button, Tooltip, TextField, CircularProgress, Autocomplete, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import iconEmployee from '../assets/PersonEmployee.png';
import iconConsultant from '../assets/PersonDaughertyConsultant.png';
import { getEmployeeData } from '../api/Employees';
import SearchContext from '../contexts/SearchContext';
import SearchBarPopper from './SearchBarPopper';
import { useAccount } from '../contexts/AccountContext';
import searchViews from '../utils/searchViews';
import colors from '../colors';
import eliminateExtraWhiteSpace from '../utils/eliminateExtraWhiteSpace';
import searchBarStyles from './SearchBar.styles';

const END_TYPING_TIMEOUT = 500; // ms

function SearchBar({ styles, activeAccount, history }) {
  const classes = searchBarStyles();
  const { setSearch, setNewSearchSubmitted, currentView, setCurrentView } =
    useContext(SearchContext);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [searchTextSubmitted, setSearchTextSubmitted] = useState('');
  const { clearActiveAccount } = useAccount();
  const isMounted = React.useRef(true);

  const searchTriggersBlocked =
    loading || searchTextSubmitted === searchValue || searchValue.length < 2;

  const getSearchResults = useCallback(
    async (active) => {
      if (!searchTriggersBlocked) {
        setLoading(true);
        try {
          const returnJson = await getEmployeeData(searchValue);
          setLoading(false);
          setError(false);
          setSearchTextSubmitted(searchValue);
          if (active) {
            if (returnJson != null) {
              const result = Object.keys(returnJson).map((key) => returnJson[key]);
              setOptions(result);
            } else {
              const fakeJson = [{ firstName: 'No', lastName: 'Results' }];
              const fakeResult = Object.keys(fakeJson).map((key) => fakeJson[key]);
              setOptions(fakeResult);
            }
          }
        } catch (e) {
          setError(true);
          setLoading(false);
          setSearchValue('');
        }
      }
    },
    [searchValue, searchTriggersBlocked],
  );

  useEffect(() => {
    isMounted.current = true;
    const timeout = setTimeout(() => {
      if (!error) {
        getSearchResults(isMounted.current, searchValue, activeAccount, currentView);
      }
    }, END_TYPING_TIMEOUT);

    return () => {
      isMounted.current = false;
      clearTimeout(timeout);
    };
  }, [searchValue, activeAccount, currentView, getSearchResults, error]);

  useEffect(() => {
    if (searchValue && searchValue.length < 2) {
      setOpen(false);
    } else {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    setSearchValue('');
    setOpen(false);
  }, [currentView, setSearchValue]);

  const handleSearchSubmit = (event, newValue) => {
    event.preventDefault();
    event.persist();
    setOpen(false);
    setSearchValue(newValue);
    setSearch(newValue);
    setNewSearchSubmitted(true);
    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!searchTriggersBlocked) {
        getSearchResults(true, searchValue, activeAccount, currentView);
      }
    }
  };

  const isFreeSoloInput = (option) => typeof option === 'string';

  return (
    <Autocomplete
      id="searchBar"
      PopperComponent={SearchBarPopper}
      freeSolo
      readOnly={loading}
      classes={{
        root: styles.searchInputRoot,
        input: styles.searchInput,
      }}
      value={searchValue}
      disableClearable
      open={open}
      onOpen={() => {
        if (searchValue.length > 1) {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
        setSearchValue('');
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length) {
          setSearchValue(eliminateExtraWhiteSpace(newInputValue));
          setOpen(true);
          if (searchTextSubmitted.length > 0) {
            setSearchTextSubmitted('');
          }
        } else {
          setOpen(false);
        }
      }}
      onKeyPress={handleKeyPress}
      onChange={(event, optionSelected) => {
        if (isFreeSoloInput(optionSelected)) {
          handleSearchSubmit(event, optionSelected);
        } else if (
          optionSelected != null &&
          'firstName' in optionSelected &&
          'lastName' in optionSelected
        ) {
          setCurrentView(
            optionSelected.person_type === 'consultant'
              ? searchViews.views.DAUGHERTY_VIEW
              : searchViews.views.CLIENT_VIEW,
          );
          if (optionSelected.person_type === 'consultant') {
            setCurrentView(searchViews.views.DAUGHERTY_VIEW);
          } else {
            setCurrentView(searchViews.views.CLIENT_VIEW);
          }
          if (optionSelected.id) {
            clearActiveAccount();
            history.push(
              `/hierarchy/${optionSelected.id}/${optionSelected.accountName}/${
                searchViews.getByPersonType(optionSelected.person_type).viewName
              }`,
            );
          } else if (optionSelected.firstName !== 'No' && optionSelected.lastName !== 'Results') {
            const newValue = `${optionSelected.firstName} ${optionSelected.lastName}`;
            handleSearchSubmit(event, newValue);
          }
        }
      }}
      filterOptions={(results) => results}
      getOptionLabel={(option) =>
        isFreeSoloInput(option) ? option : `${option.firstName} ${option.lastName} ${option.id}`
      }
      options={options}
      loading={loading}
      renderOption={(props, option) => {
        const isConsultant = option.person_type === 'consultant';
        const noResults = option.firstName === 'No' && option.lastName === 'Results';
        return (
          <Box
            component="li"
            sx={{ display: 'flex', alignItems: 'center', '& > img': { mr: 2, flexShrink: 0 } }}
            className={classes.optionItemBox}
            {...props}
          >
            {!noResults && (
              <img
                className={classes.personIcon}
                src={isConsultant ? iconConsultant : iconEmployee}
                aria-label={isConsultant ? 'consultant-item' : 'employee-item'}
              />
            )}
            <span className={classes.optionItemText}>
              {option.firstName} {option.lastName}
              {option.accountName ? ` - ${option.accountName}` : ''}
              {option.title ? ` - ${option.title}` : ''}
            </span>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          className={classes.searchBarTextInput}
          name="searchBarValue"
          id="searchBarValue"
          {...params}
          label="Search for a person..."
          variant="outlined"
          disabled={loading}
          InputProps={{
            ...params.InputProps,
            'data-testid': 'searchbox-textfield',
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    data-testid="global-search-spinner"
                    color="primary"
                    size={15}
                    className={classes.searchBarCircularProgress}
                  />
                ) : null}

                <Tooltip title="Search" arrow>
                  <span>
                    <Button
                      data-testid="search-button"
                      className={classes.magnifierSearchButton}
                      onClick={() =>
                        getSearchResults(true, searchValue, activeAccount, currentView)
                      }
                      disabled={searchTriggersBlocked}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        title="Search"
                        color={searchTriggersBlocked ? colors.darkGrey : colors.black}
                      />
                    </Button>
                  </span>
                </Tooltip>
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchBar;
