class Breadcrumbs {
  constructor(breadcrumbs, anchorCrumb) {
    this.breadcrumbs = breadcrumbs || [];
    this.anchorCrumb = anchorCrumb; // breadcrumbs can grow from anchorCrumb in either direction
  }

  equals(other) {
    return (
      this.anchorCrumb &&
      other &&
      other.anchorCrumb &&
      this.anchorCrumb.id === other.anchorCrumb.id &&
      this.breadcrumbs.length === other.breadcrumbs.length &&
      this.breadcrumbs.every((crumb, index) => crumb.equals(other.breadcrumbs[index]))
    );
  }
}

export default Breadcrumbs;
