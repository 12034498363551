import { format } from 'date-fns';

export const formatDate = (date) => {
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (error) {
    return '';
  }
};

// See this: https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
export const parseWithLocalTimezone = (dateString) => new Date(`${dateString}T00:00:00`);

export const dateWithoutTime = (date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};
