import React, { useState, useRef } from 'react';
import { Button, Grid, Box, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { uploadAccountLogo } from '../api/Accounts';
import { useAccount } from '../contexts/AccountContext';
import PreviewBox from './PreviewBox';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
  previewLogo: {
    maxWidth: 150,
  },
});

function UploadLogo({ account, onSuccess, onError }) {
  const { updateAccount } = useAccount() || {};

  const [selectedFile, setSelectedFile] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const classes = useStyles();
  const hiddenFileInput = useRef(null);

  const allowedFileExtensions = ['png', 'jpg', 'jpeg', 'gif'];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const extension = file.name.split('.').pop().toLowerCase();

    if (!allowedFileExtensions.includes(extension)) {
      onError('The selected file type is not allowed.');
    } else {
      setSelectedFile(file);
    }
  };

  const handleUploadClick = () => {
    if (!selectedFile) return;

    const previewFileURL = account && account.logoURL ? account.logoURL : null;
    let previewFile = null;

    if (previewFileURL) {
      previewFile = previewFileURL.substr(previewFileURL.length - 40);
    }

    setIsUploading(true);

    uploadAccountLogo(account.id, selectedFile, previewFile).then((newAccount) => {
      if (newAccount === null) {
        onError('There was an error uploading your logo.');
      } else {
        const accountUpdated = {
          ...account,
          logoURL: newAccount.logoURL,
        };
        setSelectedFile(null);
        onSuccess(newAccount);
        updateAccount(accountUpdated);
      }
      setIsUploading(false);
    });
  };

  const uploadIcon = () => (
    <FontAwesomeIcon size="3x" icon={faCloudUploadAlt} title="Upload" style={{ zIndex: 2 }} />
  );

  return (
    <Box mt={4}>
      <Grid container spacing={4} justifyContent="center">
        {account && (
          <>
            <Grid item>
              <PreviewBox title="Current logo">
                {account.logoURL ? (
                  <img
                    className={classes.previewLogo}
                    id="current-logo-preview"
                    alt={`${account.name} Logo`}
                    src={account.logoURL}
                  />
                ) : (
                  <span>{account.name}</span>
                )}
              </PreviewBox>
            </Grid>
            <Grid item>
              <PreviewBox title="Upload logo" onClick={() => hiddenFileInput.current.click()}>
                {selectedFile ? (
                  <img
                    className={classes.previewLogo}
                    src={URL.createObjectURL(selectedFile)}
                    id="upload-logo-preview"
                    alt="Uploaded logo preview"
                  />
                ) : (
                  uploadIcon()
                )}
              </PreviewBox>
            </Grid>
            <Grid item xs={12}>
              {isUploading && <LinearProgress />}
              <p>
                Supported files:
                {allowedFileExtensions.join(', ')}
              </p>
              <Button
                id="upload-button"
                onClick={handleUploadClick}
                variant="contained"
                color="primary"
                disabled={!selectedFile || isUploading}
              >
                Upload
              </Button>
            </Grid>
            <input
              type="file"
              aria-label="hidden_file_input"
              onChange={handleFileChange}
              hidden
              ref={hiddenFileInput}
            />
          </>
        )}
      </Grid>
    </Box>
  );
}

export default UploadLogo;
