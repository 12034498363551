const groupBy = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
};

export const assignRole = (role, roles) => {
  return !!roles.find((item) => item === role);
};

export const roleMapper = (data) => {
  return data
    .map((i, idx) => {
      // eslint-disable-next-line no-nested-ternary
      const role = idx === 0 ? 'Admin' : idx === 1 ? 'Team' : 'Client';
      return i.map((j) => {
        return { ...j, role };
      });
    })
    .flat();
};

export const appendRole = (data) => {
  return data.map((item, idx) => {
    return item.map((user) => {
      const newUser = user;
      if (idx === 0) {
        newUser.role = 'admin';
      }
      if (idx === 1) {
        newUser.role = 'team';
      }
      if (idx === 2) {
        newUser.role = 'client';
      }
      return newUser;
    });
  });
};

export const transformDataByEmail = (data) => {
  const users = [];
  const roleData = roleMapper(data);
  const groupedData = groupBy(roleData, 'email');
  const keys = Object.keys(groupedData);

  // Loop through and conform the data
  keys.forEach((key) => {
    const email = key;
    const element = groupedData[key].flat();
    const roles = element.map((item) => {
      return item.role;
    });
    users.push({
      id: element[0].id,
      firstName: element[0].firstName,
      lastName: element[0].lastName,
      upn: element[0].upn,
      email,
      admin: assignRole('Admin', roles),
      team: assignRole('Team', roles),
      client: assignRole('Client', roles),
    });
  });
  return users;
};

export const GridRoles = {
  admin: 'admin',
  clientLeadership: 'client',
  teamLeadership: 'team',
};

export const getRoleDifference = (newRoles, currentRoles) => {
  return currentRoles.filter((object1) => {
    return !newRoles.some((object2) => {
      return object1.value === object2.value;
    });
  });
};

export const convertOriginalToSingle = (data) => {
  let admin = false;
  let client = false;
  let team = false;

  const filteredData = data
    .map((item) => {
      admin = !admin ? item.role === 'admin' : admin;
      client = !client ? item.role === 'client' : client;
      team = !team ? item.role === 'team' : team;

      return {
        ...item,
        admin: item.role === 'admin',
        client: item.role === 'client',
        team: item.role === 'team',
      };
    })
    .flat();

  return [{ ...filteredData[0], admin, client, team }];
};

export const roleFiller = (current) => {
  const newRoles = current;
  return newRoles.map((role) => {
    const temp = role;
    if (!temp.admin) {
      temp.admin = false;
    }
    if (!temp.team) {
      temp.team = false;
    }
    if (!temp.client) {
      temp.client = false;
    }
    return temp;
  });
};

export const filterDataToUpdateByRoles = (orginial, newValues, orginialWithIds) => {
  const updatedValues = newValues[0];
  const tracker = [];

  roleFiller(orginial).filter(
    (og) =>
      !roleFiller(newValues).some((newValue) => {
        if (newValue.admin !== og.admin) {
          tracker.push({ admin: newValue.admin });
        }
        if (newValue.team !== og.team) {
          tracker.push({ team: newValue.team });
        }
        if (newValue.client !== og.client) {
          tracker.push({ client: newValue.client });
        }
        return (
          newValue.admin === og.admin && newValue.team === og.team && newValue.client === og.client
        );
      }),
  );

  return tracker.map((t) => {
    const tempT = t;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key] of Object.entries(t)) {
      const found = orginialWithIds.find((item) => item.role === key);
      tempT.id = found ? found.id : '';
      tempT.action = found ? 'DELETE' : 'CREATE';
      tempT.role = key;
    }
    tempT.firstName = updatedValues.firstName;
    tempT.lastName = updatedValues.lastName;
    tempT.email = updatedValues.email;
    tempT.upn = updatedValues.upn;

    return tempT;
  });
};

export const getPreviousUpnForDelete = (updatedUsers, originalUsers) => {
  const originalUpnList = [];
  originalUsers.forEach((eachRole) => {
    eachRole.forEach((eachUserInRole) => {
      if (!originalUpnList.find((item) => item === eachUserInRole.upn)) {
        originalUpnList.push(eachUserInRole.upn);
      }
    });
  });

  const previousUpnForDelete = [];
  originalUpnList.forEach((eachOriginalUpn) => {
    let upnExistsAfterUpdate = false;
    updatedUsers.forEach((eachUpdatedUser) => {
      if (eachOriginalUpn === eachUpdatedUser.upn) {
        upnExistsAfterUpdate = true;
      }
    });
    if (!upnExistsAfterUpdate) {
      previousUpnForDelete.push(eachOriginalUpn);
    }
  });

  return previousUpnForDelete.length === 1 ? previousUpnForDelete[0] : undefined;
};

export const getUserRoleIds = (originalUsers, upn) => {
  const userIds = [];
  originalUsers.forEach((eachRole) => {
    eachRole.forEach((eachOriginalUser) => {
      if (eachOriginalUser.upn === upn) {
        userIds.push(eachOriginalUser.id);
      }
    });
  });
  return userIds;
};

export default groupBy;
