/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Card, CardContent, Tooltip } from '@mui/material';
import searchViews from '../utils/searchViews';
import { generateLinkedInUrl, generateSalesforceUrl } from '../utils/links';
import css from './OrgChartNode.styles';

function OrgChartNode({
  employeeInfo: {
    data: {
      id,
      firstName,
      lastName,
      title,
      deliveryRole,
      primaryLOS,
      accountName,
      department,
      type,
      daughertyManagerName,
      daughertyManagerId,
      clientManagerName,
      clientManagerId,
      countDBSConsultants,
      countDirectEmployees,
      countNotDBSContractors,
      relationshipStatus,
    },
  },
  currentView,
  exportInProgress,
}) {
  const styles = css(exportInProgress);
  const relationships = {
    'Trusted Advisor': styles.relTrustedAdvisor,
    Favorable: styles.relFavorable,
    Neutral: styles.relNeutral,
    Target: styles.relTarget,
    Unfavorable: styles.relUnfavorable,
  };
  const renderIcon = (iconStyles, socialMediaName, linkGeneretionFunc) => {
    return (
      <a
        href={linkGeneretionFunc(firstName, lastName, accountName)}
        target="_blank"
        rel="noreferrer"
      >
        <span
          style={{ ...styles.iconSpan, ...iconStyles }}
          title={`View in ${socialMediaName}`}
          aria-label={`View in ${socialMediaName}`}
          role="link"
          tabIndex={0}
        />
      </a>
    );
  };

  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: '#FFF',
        overflow: 'visible',
        boxSizing: 'border-box',
        height: '100%',
      }}
    >
      <Card key={id} role="button" style={styles.card}>
        <CardContent>
          {type === 'Employee' && (
            <div style={styles.employeeNodeContainer}>
              <div
                style={{ ...styles.employeeInfoContainer, ...relationships[relationshipStatus] }}
                id={`${firstName} ${lastName}`}
                data-testid={`${firstName} ${lastName}`}
              >
                <p style={styles.employeeName}>{`${firstName} ${lastName}`}</p>
                <p style={styles.employeeTitle}>{title || 'No Title Found'}</p>
                {department && <p style={styles.employeeDepartment}>{department}</p>}
                {!exportInProgress && (
                  <div style={styles.socialIcons}>
                    {renderIcon(styles.linkedInPng, 'LinkedIn', generateLinkedInUrl)}
                    {renderIcon(styles.salesforcePng, 'Salesforce', generateSalesforceUrl)}
                  </div>
                )}
              </div>
              <div style={{ ...styles.nodeFooterContainer, ...styles[`footerContainer${type}`] }}>
                <div
                  style={
                    type === 'DbsConsultant'
                      ? { ...styles.nodeFooterCounters, paddingBottom: '7px' }
                      : styles.nodeFooterCounters
                  }
                >
                  <div style={{ ...styles.reports, ...styles.pills, ...styles.daughertyCount }}>
                    {Math.trunc(countDBSConsultants)}
                  </div>
                  <div style={{ ...styles.reports, ...styles.pills, ...styles.nonDaughertyCount }}>
                    {Math.trunc(countNotDBSContractors)}
                  </div>
                  <div style={{ ...styles.reports, ...styles.pills, ...styles.directCount }}>
                    {Math.trunc(countDirectEmployees)}
                  </div>
                </div>
              </div>
            </div>
          )}

          {type === 'DbsConsultant' && (
            <div>
              <div style={styles.topBox}>
                <p style={{ ...styles.personName, ...styles.text }}>
                  {`${firstName.trim() || ''} ${lastName.trim() || ''}`}
                </p>
                <p style={styles.personTitle}>{title ?? 'Title not found'}</p>
                <p data-testid="delivery-role" style={styles.personRole}>
                  {deliveryRole ?? 'Role not found'}
                </p>
                <p style={styles.los}>{primaryLOS ?? 'LOS not found'}</p>
              </div>

              {currentView === searchViews.views.DAUGHERTY_VIEW && (
                <div style={{ ...styles.footer, ...styles.text }}>
                  <span style={styles.label}>Client manager:</span>
                  <Tooltip title="View the Client management structure.">
                    <span>
                      {clientManagerName ? (
                        <a
                          href={`/hierarchy/${clientManagerId}/${accountName}/client`}
                          style={{ ...styles.textAlignStart, ...styles.reportsTo }}
                        >
                          <span data-testid="reports-to-client">{clientManagerName}</span>
                        </a>
                      ) : (
                        <span data-testid="reports-to-client" style={styles.label}>
                          -
                        </span>
                      )}
                    </span>
                  </Tooltip>
                </div>
              )}

              {currentView === searchViews.views.CLIENT_VIEW && (
                <div style={{ ...styles.footer, ...styles.text }}>
                  <span style={styles.label}>Daugherty manager:</span>
                  <Tooltip title="View the Daugherty management structure." placement="top" arrow>
                    <span>
                      {daughertyManagerName ? (
                        <a
                          href={`/hierarchy/${daughertyManagerId}/${accountName}/daugherty`}
                          style={{ ...styles.textAlignStart, ...styles.reportsTo }}
                        >
                          <span data-testid="reports-to-client">{daughertyManagerName}</span>
                        </a>
                      ) : (
                        <span data-testid="reports-to-client" style={styles.label}>
                          -
                        </span>
                      )}
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default OrgChartNode;
