import { LinearProgress, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';

import React, { useEffect, useState } from 'react';

import sendFeedbackMessage from '../api/Feedback';
import colors from '../colors';
import toastMessage from '../utils/toastMessage/toastMessageUtility';

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.white,
    background: colors.primary,
    marginBottom: theme.spacing(1),
  },
  dialog: {
    width: '100%',
    my: '1rem',
  },
  container: {
    width: '40vw',
  },
}));

function FeedbackDrawer({ open, setOpen, onConfirm, user }) {
  const [message, setMessage] = useState('');
  const [isSendingMessage, setSendingStatus] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const classes = useStyles();
  const email = user.username;
  const handleMessageChange = (event) => {
    const newValue = event.target.value;
    setMessage(newValue);
  };

  useEffect(() => {
    if (email.length && message.trim().length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, message]);

  const sendMessage = () => {
    setSendingStatus(true);
    sendFeedbackMessage(email, message).then((response) => {
      if (response.status === 200) {
        toastMessage.success('Feedback sent successfully!');
        setSendingStatus(false);
        setOpen(false);
        onConfirm();
      } else {
        toastMessage.error('Something went wrong. Please try again later');
        setSendingStatus(false);
      }
    });
  };

  return (
    <div>
      <Drawer
        open={open}
        anchor="right"
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.container}>
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Feedback
          </DialogTitle>
          {isSendingMessage && <LinearProgress />}
          <DialogContent>
            <div id="alert-dialog-description">
              <Typography paragraph>
                Need assistance or have a suggestion? Submit a request below and we will get to
                work!
              </Typography>
              <Typography paragraph>
                If you think the problem is a bug describe it in detail below and we will collect
                full details to report it to development.
              </Typography>
              <Typography paragraph>
                If the information displayed appears inaccurate, please reach out to the Client
                Team to correct the data in Salesforce.
              </Typography>
              <TextField
                label="Your contact email"
                value={email}
                id="email-input"
                type="email"
                className={classes.dialog}
                variant="outlined"
                margin="normal"
                disabled
              />
              <TextField
                label="Message"
                multiline
                id="message-input"
                className={classes.dialog}
                onChange={handleMessageChange}
                value={message}
                minRows={5}
                variant="outlined"
                margin="normal"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={sendMessage}
              variant="contained"
              color="primary"
              aria-label="submit-button"
              autoFocus
              id="submit-button"
              disabled={isDisabled}
            >
              Submit Feedback
            </Button>
          </DialogActions>
        </div>
      </Drawer>
    </div>
  );
}

export default FeedbackDrawer;
