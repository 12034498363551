import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { withRouter } from 'react-router-dom';
import { getAccounts } from '../api/Accounts';
import AdminUserContext from '../contexts/AdminUserContext';
import toastMessage from '../utils/toastMessage/toastMessageUtility';
import AccountPicker from './AccountPicker';
import UploadLogo from './UploadLogo';
import colors from '../colors';

const useStyles = makeStyles({
  headerText: {
    fontSize: '26px',
    fontWeight: 'normal',
    cursor: 'pointer',
    lineHeight: '35px',
    color: colors.secondary,
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function AccountEdit() {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState();

  const { isClientUser } = useContext(AdminUserContext);

  const updateAccounts = () => {
    let isMounted = true;
    setLoading(true);
    getAccounts()
      .then((r) => {
        if (isMounted) setAccounts(r);
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    if (isClientUser) {
      updateAccounts();
    }
  }, [setAccounts, setAccount, isClientUser]);

  const handleAccountChange = (newAccount) => {
    if (!newAccount) return null;
    return setAccount(newAccount);
  };

  const handleSuccessUpload = (updatedAccount) => {
    setAccount(updatedAccount);
    return updateAccounts();
  };

  return (
    <main className={classes.container}>
      <div className={classes.titleRow}>
        <h3 className={classes.headerText}>Client Logo</h3>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Typography data-testid="change-logo-header" variant="h6">
            Change Logo
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {error && (
            <Typography variant="body1" aria-errormessage="Unable to load accounts">
              Unable to load accounts
            </Typography>
          )}
          {!error && !isLoading && (
            <AccountPicker accounts={accounts} selected={account} onChange={handleAccountChange} />
          )}
          {!error && isLoading && <CircularProgress />}
        </Grid>
        {account && (
          <Grid item xs={12}>
            <UploadLogo
              account={account}
              onSuccess={handleSuccessUpload}
              onError={toastMessage.error}
            />
          </Grid>
        )}
      </Grid>
    </main>
  );
}

export default withRouter(AccountEdit);
