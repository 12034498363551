import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 'calc(90vh - 100px)',
  },
  headersBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
