import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import RelationshipLegend from './RelationshipLegend';
import ReportLegend from './ReportLegend';
import AdminUserContext from '../contexts/AdminUserContext';

const useStyles = makeStyles({
  legend: {
    position: 'fixed',
    bottom: '6rem',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '1rem',
  },
  innerLegend: {
    overflow: 'hidden',
    borderRadius: '1rem',
    margin: '0 auto',
    display: 'flex',
    boxShadow: '0 2px 6px rgba(0,0,0,.2)',
  },
  keybox: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: '.4em 1em',
  },
});

function Legends() {
  const classes = useStyles();
  const { isClientUser } = useContext(AdminUserContext);

  return (
    <div className={classes.legend}>
      {isClientUser && <RelationshipLegend classes={classes} />}
      <ReportLegend classes={classes} />
    </div>
  );
}

export default Legends;
