import React, { useState } from 'react';
import { Box, Tooltip, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import FeedbackDrawer from './FeedbackDrawer';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    bottom: 0,
    marginTop: '20px',
    position: 'fixed',
    backgroundColor: 'white',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerElement: {
    margin: '0px 8px',
  },
  footerEmptyElement: {
    margin: '0px 8px',
  },
  iconButton: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  headerText: {
    fontWeight: 700,
    cursor: 'pointer',
    color: 'black',
  },
}));

export default function Footer({ user }) {
  const classes = useStyles();
  const [feedBackOpen, setFeedBackOpen] = useState(false);

  const handleOnFeedBack = () => {
    setFeedBackOpen(true);
  };

  return (
    <Box
      component="footer"
      className={classes.root}
      sx={{
        borderTop: '3px',
        color: 'black',
        textAlign: 'center',
        padding: '1rem',
        boxShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      }}
    >
      <div className={classes.footer}>
        <p className={classes.footerElement}>
          <Tooltip title="Feedback" arrow>
            <Box
              component={ButtonBase}
              data-testid="feedback-button"
              onClick={() => handleOnFeedBack()}
              className={classes.headerText}
            >
              <RateReviewOutlinedIcon className={classes.iconButton} />
              <p className={classes.footerElement}>Submit Feedback </p>
            </Box>
          </Tooltip>
        </p>
        <p className={classes.footerElement}>For Daugherty Business Solutions internal use only</p>
        <p id="app-version" className={classes.footerElement}>
          Version 2023.2.1
        </p>
      </div>
      <FeedbackDrawer open={feedBackOpen} setOpen={setFeedBackOpen} user={user} />
    </Box>
  );
}
