import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  previewArea: {
    width: 250,
    height: 250,
    border: '1px dashed rgba(176, 176, 176, 0.87)',
    borderRadius: 10,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    marginTop: '1em',
  },
});

function PreviewBox({ title, onClick, children }) {
  const classes = useStyles();

  return (
    <Paper
      className={classes.previewArea}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>{children}</Grid>
        <Grid item>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PreviewBox;
