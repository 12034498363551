import React, { useRef, useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import { OrgChart } from 'd3-org-chart';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import { SpeedDialAction } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import {
  ExpandMore,
  ExpandLess,
  PhotoSizeSelectActual,
  Image,
  SwapHoriz,
  SwapVert,
} from '@mui/icons-material';
import searchViews from '../utils/searchViews';
import OrgChartNode from './OrgChartNode';
import SearchContext from '../contexts/SearchContext';
import styles from './OrgChartComponent.styles';

function OrgChartComponent({ data }) {
  const d3Container = useRef(null);
  const [chart] = useState(new OrgChart());
  const [fullExportInProgress, setFullExportInProgress] = useState(false);
  const [sliceExportInProgress, setSliceExportInProgress] = useState(false);
  const [exportDone, setExportDone] = useState(false); // required for forcing renender of org-chart to reactivate social icons after end of file-export
  const { currentView, setCurrentView } = useContext(SearchContext);
  const { routePersonId } = useParams();

  const renderExpandIcon = useCallback(
    (node) => {
      return !sliceExportInProgress && !fullExportInProgress ? (
        <span style={styles.expandIcon}>
          {node.children ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
        </span>
      ) : null;
    },
    [sliceExportInProgress, fullExportInProgress],
  );

  useEffect(() => {
    if (
      (data && data !== chart.data() && d3Container.current) ||
      fullExportInProgress ||
      sliceExportInProgress ||
      exportDone
    ) {
      chart
        .container(d3Container.current)
        .data(data)
        .nodeWidth(() => 200)
        .nodeHeight(() => 124)
        .compactMarginBetween(() => 60)
        .buttonContent((node) => {
          return ReactDOMServer.renderToStaticMarkup(renderExpandIcon(...node.node));
        })
        .parentNodeId((d) => {
          return currentView === searchViews.views.CLIENT_VIEW
            ? d.clientManagerId
            : d.daughertyManagerId;
        })
        .nodeContent((d) => {
          return ReactDOMServer.renderToString(
            <OrgChartNode
              employeeInfo={d}
              currentView={currentView}
              setCurrentView={setCurrentView}
              exportInProgress={fullExportInProgress || sliceExportInProgress}
            />,
          );
        })
        .render()
        .setCentered(!sliceExportInProgress && !exportDone ? routePersonId : undefined)
        .render();

      if (exportDone) {
        setExportDone(false);
      }
    }
  }, [
    data,
    chart,
    routePersonId,
    currentView,
    setCurrentView,
    fullExportInProgress,
    sliceExportInProgress,
    exportDone,
    renderExpandIcon,
  ]);

  return (
    <div>
      <Box sx={{ float: 'left', position: 'absolute', bottom: 100, left: '2.5%' }}>
        <SpeedDial ariaLabel="SpeedDial basic example" icon={<SpeedDialIcon />}>
          <SpeedDialAction
            key="export_image"
            icon={<Image />}
            tooltipTitle="Save Visible as Image"
            onClick={(e) => {
              setSliceExportInProgress(true);
              e.stopPropagation();
              setTimeout(() => {
                chart.exportImg({
                  onLoad: () => {
                    setSliceExportInProgress(false);
                    setExportDone(true);
                  },
                });
              }, 1000);
            }}
          />
          <SpeedDialAction
            key="export_full_image"
            icon={<PhotoSizeSelectActual />}
            tooltipTitle="Save Full Chart as Image"
            onClick={(e) => {
              setFullExportInProgress(true);
              e.stopPropagation();
              setTimeout(() => {
                chart.exportImg({
                  full: true,
                  onLoad: () => {
                    setFullExportInProgress(false);
                    setExportDone(true);
                  },
                });
              }, 1000);
            }}
          />
          <SpeedDialAction
            key="view_compact"
            icon={<SwapHoriz />}
            tooltipTitle="Horizontal Layout"
            onClick={(e) => {
              e.stopPropagation();
              chart.compact(false).setCentered(routePersonId).render();
            }}
          />
          <SpeedDialAction
            key="view_expanded"
            icon={<SwapVert />}
            tooltipTitle="Vertical Layout"
            onClick={(e) => {
              e.stopPropagation();
              chart.compact(true).setCentered(routePersonId).render();
            }}
          />
        </SpeedDial>
      </Box>
      <div ref={d3Container} />
    </div>
  );
}

export default OrgChartComponent;
