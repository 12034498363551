import fileDownload from 'js-file-download';
import { formatDate } from '../utils/dates';
import apiBaseUrl from '../utils/apiBaseUrl';

const downloadUsageReport = async (startDate, endDate) => {
  const response = await fetch(
    `${apiBaseUrl}/reports/usage?start=${formatDate(startDate)}&end=${formatDate(endDate)}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'text/csv',
      },
    },
  );

  await response.blob().then((blob) => {
    const filename = `usage_report_${formatDate(startDate)}_${formatDate(endDate)}.csv`;
    fileDownload(blob, filename);
  });
};

export default downloadUsageReport;
