import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 'calc(90vh - 100px)',
  },
  headersBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'absolute',
    left: '2.5%',
    zIndex: 10,
  },
  viewTitle: {
    position: 'absolute',
    left: '5%',
    top: '150px',
    zIndex: -1,
  },
});
