import React, { useState, useEffect, useContext, useCallback } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import componentStyles from './AccountDetails.styles';
import DaughertyOrgChart from '../../components/DaughertyOrgChart';
import DaughertyLegends from '../../components/DaughertyLegends/DaughertyLegends';
import BreadcrumbBar from '../../components/BreadcrumbBar';
import BreadcrumbsBuilder from '../../services/BreadcrumbService';
import Breadcrumbs from '../../services/Breadcrumbs';
import Loading from '../../components/Loading';
import Logo from '../../components/Logo';
import Legends from '../../components/Legends';
import SearchPrompt from '../../components/SearchPrompt';
import SearchNotFound from '../../components/SearchNotFound';
import SearchContext from '../../contexts/SearchContext';
import { getEmployeeDataById, getOrphanData } from '../../api/Employees';
import LastRefreshFooter from '../../components/LastRefreshFooter';
import SubLevelsToShow from '../../components/SubLevelsToShow';
import { useAccount } from '../../contexts/AccountContext';
import searchViews from '../../utils/searchViews';

function AccountDetails({ history, styles, subLevelsToShow, setSubLevelsToShow }) {
  const { activeAccount, setAccountByName, isLoadingAccounts } = useAccount();
  const { routePersonId, routeAccount, routeView, accountId } = useParams();
  const [parentId, setParentId] = useState('');
  const [validContact, setValidContact] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [orphanData, setOrphanData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [breadcrumbData, setBreadcrumbData] = useState(new Breadcrumbs([], null));
  const {
    search,
    displayData,
    setDisplayData,
    newSearchSubmitted,
    setNewSearchSubmitted,
    setCurrentEmployee,
    currentEmployee,
    currentView,
    setCurrentView,
  } = useContext(SearchContext);
  const [newPersonIdSubmitted, setNewPersonIdSubmitted] = useState(false);
  const classes = componentStyles();

  const handleSelectedEmployeeId = useCallback(
    (empId, switchToClientView = false) => {
      if (switchToClientView) {
        setNewSearchSubmitted(true);
      }
      setParentId(empId);
      setNewPersonIdSubmitted(true);
    },
    [setNewSearchSubmitted],
  );

  const selectedTab = 0;

  useEffect(() => {
    let isSubscribed = true;
    if (routeAccount || accountId) {
      setAccountByName(routeAccount || accountId, isSubscribed);
    }
    return () => {
      isSubscribed = false;
    };
  }, [routeAccount, accountId, setAccountByName]);

  useEffect(() => {
    if (routePersonId) {
      setParentId(routePersonId);
    }
  }, [routePersonId, routeView, setCurrentView]);

  useEffect(() => {
    if (parentId || (currentEmployee && currentEmployee.id)) {
      handleSelectedEmployeeId(parentId || currentEmployee.id);
    }
  }, [parentId, currentEmployee, handleSelectedEmployeeId]);

  useEffect(() => {
    if (employeeData.length > 0) {
      const newCrumbs = newSearchSubmitted
        ? BreadcrumbsBuilder(new Breadcrumbs([], null), employeeData[0])
        : BreadcrumbsBuilder(breadcrumbData, employeeData[0]);
      if (!newCrumbs.equals(breadcrumbData)) {
        setBreadcrumbData(newCrumbs);
      }
      setDisplayData(true);
    } else {
      setDisplayData(false);
    }
  }, [newSearchSubmitted, employeeData, setBreadcrumbData, breadcrumbData, setDisplayData]);

  useEffect(() => {
    let isSubscribed = true;
    if (activeAccount.name) {
      getOrphanData(activeAccount, currentView === searchViews.views.DAUGHERTY_VIEW).then(
        (result) => {
          if (isSubscribed && result) {
            setOrphanData(result);
          }
        },
      );
    }
    return () => {
      isSubscribed = false;
    };
  }, [activeAccount, currentView]);

  useEffect(() => {
    if (currentEmployee) {
      setEmployeeData([currentEmployee]);
      setDisplayData(true);
      setParentId(currentEmployee.id);
    }

    return () => {
      setCurrentEmployee(null);
    };
  }, [currentEmployee, setCurrentEmployee, setDisplayData]);

  useEffect(() => {
    if (selectedTab.toString() === '0') {
      if (employeeData.length > 0 && !parentId) {
        setParentId(employeeData[0].id);
      }
    } else if (orphanData.length > 0 && !parentId) {
      setParentId(orphanData[0].id);
    }
  }, [selectedTab, employeeData, orphanData, parentId]);

  useEffect(() => {
    if (newPersonIdSubmitted && activeAccount.name) {
      // mimicks approach to newSearchSubmitted
      setNewPersonIdSubmitted(false);
      setIsLoadingData(true);

      getEmployeeDataById(activeAccount, parentId, currentView)
        .then((result) => {
          if (result) {
            setEmployeeData([result]);
            // const newUrl = `/hierarchy/${result.id}/${activeAccount.name}/${currentView.viewName}`;
            const newUrl = `/hierarchy/${result.id}/${activeAccount.name}`;
            if (history.location.pathname !== newUrl) {
              history.push(newUrl);
            }
            setValidContact(true);
          } else {
            setValidContact(false);
          }
        })
        .finally(() => {
          setIsLoadingData(false);
        });
    }
  }, [
    activeAccount,
    parentId,
    newPersonIdSubmitted,
    setNewPersonIdSubmitted,
    history,
    employeeData,
    currentView,
    newSearchSubmitted,
  ]);

  if (isLoadingData || isLoadingAccounts) return <Loading displayText="Loading" />;
  if (!validContact && displayData)
    return <SearchNotFound searchTerm={search} account={activeAccount.name} />;

  return (
    <div className={styles.root}>
      <main className={styles.content}>
        <div className={classes.contentContainer}>
          <div className={classes.headersBox}>
            <Logo account={activeAccount} />
          </div>
          <div>
            <h3>{currentView.fullName}</h3>
          </div>
          {displayData && employeeData.length > 0 && (
            <>
              <BreadcrumbBar
                breadcrumbs={breadcrumbData}
                currentEmployee={employeeData && employeeData.length && employeeData[0]}
              />
              <>
                <SubLevelsToShow
                  subLevelsToShow={subLevelsToShow}
                  setSubLevelsToShow={setSubLevelsToShow}
                />
                <DaughertyOrgChart
                  history={history}
                  parentId={parentId}
                  employeeData={employeeData}
                  orphanData={orphanData}
                  selectedTab={selectedTab}
                  styles={styles}
                  subLevelsToShow={subLevelsToShow}
                  handleSelectedEmployeeId={handleSelectedEmployeeId}
                  viewportWidth={window.innerWidth}
                  currentView={currentView}
                />
              </>
              {currentView === searchViews.views.CLIENT_VIEW && <Legends />}
              {currentView === searchViews.views.DAUGHERTY_VIEW && <DaughertyLegends />}
            </>
          )}

          {!displayData && <SearchPrompt />}
        </div>
        <LastRefreshFooter activeAccount={activeAccount} />
      </main>
    </div>
  );
}

export default withRouter(AccountDetails);
