import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  cardImage: {
    backgroundRepeat: 'no-repeat',
    filter:
      'drop-shadow(-2px -2px 2px #FAFAFA) drop-shadow(2px -2px 2px #FAFAFA) drop-shadow(-2px 2px 2px #FAFAFA) drop-shadow(2px 2px 2px #FAFAFA)',
    maxHeight: '100%',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
  },
  cardText: {
    verticalAlign: 'center',
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  imageContainer: {
    height: '110px',
    width: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
});

function Logo({ account }) {
  const classes = useStyles();

  return (
    <div style={{ zIndex: 40 }}>
      <div className={classes.imageContainer} key={account.name}>
        {account.logoURL ? (
          <img alt={`${account.name} Logo`} className={classes.cardImage} src={account.logoURL} />
        ) : (
          <h1 className={classes.cardText}>{account.name}</h1>
        )}
      </div>
    </div>
  );
}

export default Logo;
