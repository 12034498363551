import makeStyles from '@mui/styles/makeStyles';
import colors from '../colors';

export default makeStyles({
  gridItem: {
    width: '100%',
  },
  card: {
    height: '155.594px',
    width: '100%',
    overflow: 'hidden',
  },
  card_clickable: {
    '&:hover': {
      border: '2px solid #548c3f',
    },
  },
  topBox: {
    backgroundColor: colors.primary,
    color: 'white',
    minHeight: '4.8em',
    textAlign: 'center',
    paddingBottom: '14px',
    paddingTop: '0.4em',
  },
  text: {
    fontSize: '.7em',
    margin: '0',
  },
  personName: {
    fontWeight: '700',
    margin: '0 0 4px',
    fontSize: '.8em',
  },
  personTitle: {
    fontStyle: 'italic',
    fontSize: '.7em',
    margin: '0',
  },
  los: {
    fontSize: '.7em',
    margin: '0',
  },
  reportsTo: {
    display: 'flex',
    fontSize: '.8em',
  },
  personRole: {
    fontSize: '.7em',
    margin: '0',
  },
  label: {
    fontWeight: '700',
    color: colors.darkGrey,
    marginInline: '.5rem',
  },
  textAlignStart: {
    textAlign: 'start',
  },
  footer: {
    paddingTop: '.6em',
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: '2px',
  },
  counters: {
    paddingTop: '.3em',
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: '2px',
  },
  reportCount: {
    color: 'white',
    fontSize: '1rem',
    width: '4rem',
    borderRadius: '0.5rem',
    textAlign: 'center',
    margin: '0 2px 0 2px !important',
  },
});
