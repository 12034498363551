import Breadcrumb from './Breadcrumb';
import Breadcrumbs from './Breadcrumbs';

const BreadcrumbsBuilder = (existingBreadcrumbs, selectedEmployee) => {
  const newBreadcrumbs = new Breadcrumbs(
    existingBreadcrumbs.breadcrumbs,
    existingBreadcrumbs.anchorCrumb,
  );
  const { id, parentId } = selectedEmployee;
  const selectedEmployeeCrumb = new Breadcrumb(selectedEmployee);
  const crumbIndex = existingBreadcrumbs.breadcrumbs.findIndex((crumb) =>
    crumb.equals(selectedEmployeeCrumb),
  );
  const parentCrumbIndex = existingBreadcrumbs.breadcrumbs.findIndex(
    (crumb) => crumb.id === parentId,
  );
  const anchorCrumbIndex = existingBreadcrumbs.breadcrumbs.findIndex(
    (crumb) => existingBreadcrumbs.anchorCrumb && existingBreadcrumbs.anchorCrumb.id === crumb.id,
  );
  if (crumbIndex === -1) {
    if (parentCrumbIndex === -1) {
      if (newBreadcrumbs.breadcrumbs.length && newBreadcrumbs.breadcrumbs[0].parentId === id) {
        // selectedEmployee is the parent of the current head
        newBreadcrumbs.breadcrumbs = [selectedEmployeeCrumb, ...newBreadcrumbs.breadcrumbs];
      } else {
        // selectedEmployee is not in the list and its parent is not in the list
        newBreadcrumbs.breadcrumbs = [selectedEmployeeCrumb];
        newBreadcrumbs.anchorCrumb = selectedEmployeeCrumb;
      }
    } else {
      // selectedEmployee's parent is in the list
      newBreadcrumbs.breadcrumbs = [
        ...newBreadcrumbs.breadcrumbs.slice(0, parentCrumbIndex + 1),
        selectedEmployeeCrumb,
      ];

      /* eslint-disable prefer-destructuring */
      newBreadcrumbs.anchorCrumb = newBreadcrumbs.breadcrumbs[0];
    }
  } else if (
    anchorCrumbIndex !== -1 &&
    newBreadcrumbs.breadcrumbs.length > Math.abs(anchorCrumbIndex - crumbIndex) + 1
  ) {
    // selectedEmployee is already in the crumbs, but there are crumbs outside of it and the anchor
    newBreadcrumbs.breadcrumbs = newBreadcrumbs.breadcrumbs.slice(
      Math.min(crumbIndex, anchorCrumbIndex),
      Math.max(crumbIndex, anchorCrumbIndex) + 1,
    );
  }
  return newBreadcrumbs;
};

export default BreadcrumbsBuilder;
