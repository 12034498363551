import React from 'react';

import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Divider, Button } from '@mui/material';
import { refreshAllData } from '../api/RefreshAllData';
import colors from '../colors';

const useStyles = makeStyles({
  headerText: {
    fontSize: '26px',
    fontWeight: 'normal',
    color: colors.secondary,
  },
  subtitle: {
    fontSize: '20px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  refreshContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  refreshButton: {
    alignSelf: 'center',
    textTransform: 'none',
    width: '25em',
  },
  faColor: {
    color: '#fff',
    zIndex: '25',
    marginRight: '.5em',
  },
  instructions: {
    flexBasis: '200%',
    marginRight: '1em',
  },
});

function AdminDataRefresh({ setLoadingContacts }) {
  const classes = useStyles();

  const handleRefreshAll = async () => {
    setLoadingContacts(true);
    await refreshAllData();
    setLoadingContacts(false);
  };

  return (
    <div className={classes.root}>
      <Divider />
      <h3 className={classes.headerText}>Refresh Data</h3>
      <div className={classes.refreshContent}>
        <div className={classes.instructions}>
          <Typography className={classes.subtitle}>Refresh Employee and Account Data</Typography>
          <p>
            This option allows admins to refresh all employee and account data and their
            relationships within the application.
          </p>
        </div>
        <Button
          id="refresh-all-button"
          onClick={handleRefreshAll}
          className={classes.refreshButton}
          variant="contained"
          color="secondary"
          fullWidth
          startIcon={<FontAwesomeIcon className={classes.faColor} icon={faSync} />}
        >
          Refresh All
        </Button>
      </div>
    </div>
  );
}

export default AdminDataRefresh;
