import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  optionItemBox: {
    display: 'flex',
    alignItems: 'center',
  },
  personIcon: { width: '15px' },
  optionItemText: { marginTop: '5px' },
  searchBarTextInput: { width: '17.5em' },
  searchBarCircularProgress: { margin: '0px 7.5px' },
  magnifierSearchButton: {
    fontSize: '20px',
    cursor: 'pointer',
    minWidth: '20px',
  },
});
