import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../colors';

const useStyles = makeStyles({
  main: {
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.primary,
  },
});

function SearchPrompt() {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.main} variant="h5">
        Please search for a person&apos;s name using the search box above
      </Typography>
    </div>
  );
}

export default SearchPrompt;
