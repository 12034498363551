/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { Card, CardContent, Link, Tooltip } from '@mui/material';
import styles from './DaughertyConsultantCard.styles';
import searchViews from '../utils/searchViews';
import SearchContext from '../contexts/SearchContext';

function DaughertyConsultantCard({ contact, key, reportsToClickHandler, clickable }) {
  const classes = styles();
  const {
    firstName,
    lastName,
    title,
    primaryLOS,
    deliveryRole,
    clientManagerName,
    clientManagerId,
    daughertyManagerName,
    daughertyManagerId,
    totalReports,
  } = contact;
  const { currentView, setCurrentView } = useContext(SearchContext);

  const clickReportsToHandler = (event) => {
    event.stopPropagation();
    if (currentView === searchViews.views.DAUGHERTY_VIEW) {
      setCurrentView(searchViews.views.CLIENT_VIEW);
      reportsToClickHandler(clientManagerId, true);
    } else {
      setCurrentView(searchViews.views.DAUGHERTY_VIEW);
      reportsToClickHandler(daughertyManagerId, true);
    }
  };

  return (
    <Card
      key={key}
      className={`${classes.card} ${clickable ? classes.card_clickable : ''}`}
      role="button"
    >
      <CardContent>
        <div className={classes.topBox}>
          <p className={`${classes.personName} ${classes.text}`}>
            {`${firstName.trim() || ''} ${lastName.trim() || ''}`}
          </p>
          <p className={classes.personTitle}>{title ?? 'Title not found'}</p>
          <p data-testid="delivery-role" className={classes.personRole}>
            {deliveryRole ?? 'Role not found'}
          </p>
          <p className={classes.los}>{primaryLOS ?? 'LOS not found'}</p>
        </div>

        {currentView === searchViews.views.DAUGHERTY_VIEW && (
          <div className={`${classes.footer} ${classes.text}`}>
            <span className={classes.label}>Client manager:</span>
            <Tooltip title="View the Client management structure.">
              <span>
                <Link
                  disabled={!clientManagerId}
                  className={classes.textAlignStart}
                  component="button"
                  variant="body2"
                  onClick={clickReportsToHandler}
                  underline={clientManagerName ? 'always' : 'none'}
                >
                  <span data-testid="reports-to-client" className={classes.reportsTo}>
                    {clientManagerName ?? '-'}
                  </span>
                </Link>
              </span>
            </Tooltip>
          </div>
        )}

        {currentView === searchViews.views.CLIENT_VIEW && (
          <div className={`${classes.footer} ${classes.text}`}>
            <span className={classes.label}>Daugherty manager:</span>
            <Tooltip title="View the Daugherty management structure." placement="top" arrow>
              <span>
                <Link
                  disabled={!daughertyManagerId}
                  className={classes.textAlignStart}
                  component="button"
                  variant="body2"
                  onClick={clickReportsToHandler}
                  underline={daughertyManagerName ? 'always' : 'none'}
                >
                  <span data-testid="reports-to-client" className={classes.reportsTo}>
                    {daughertyManagerName ?? '-'}
                  </span>
                </Link>
              </span>
            </Tooltip>
          </div>
        )}

        <div className={`${classes.counters}`}>
          <div
            data-testid="total-report-pill"
            className={`${classes.reportCount} reports pills daugherty-count`}
          >
            {totalReports}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default DaughertyConsultantCard;
