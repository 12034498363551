import React from 'react';
import styles from './DaughertyLegends.styles';

function DaughertyReportLegend() {
  const classes = styles();

  return (
    <div className={classes.innerLegend}>
      <div
        data-testid="daugherty-report-legend"
        className={`${classes.keybox} reports daugherty-count`}
      >
        Number of Daugherty Consultants
      </div>
    </div>
  );
}

export default DaughertyReportLegend;
