import React from 'react';

function LastRefreshFooter({ activeAccount }) {
  const { lastRefreshMs } = activeAccount;
  if (lastRefreshMs && lastRefreshMs > 0) {
    const lastRefreshString = `Date and time of last data refresh: ${new Date(
      lastRefreshMs,
    ).toGMTString()}.`;

    return <div className="footer">{lastRefreshString}</div>;
  }
  return null;
}

export default LastRefreshFooter;
