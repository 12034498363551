import axios from 'axios';
import toastMessage from '../utils/toastMessage/toastMessageUtility';
import apiBaseUrl from '../utils/apiBaseUrl';

export const getAccounts = async () => {
  let accountData = [];

  const response = fetch(`${apiBaseUrl}/accounts`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  await response
    .then((res) => res.json())
    .then((data) => {
      accountData = data;
    })
    .catch(() => {
      toastMessage.error('An error has occurred fetching the Client information');
    });

  accountData = accountData.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return accountData.map((account) => ({
    name: account.name,
    active: true,
    id: account.id,
    logoURL: account.logoURL,
    lastRefreshMs: account.lastRefreshMs,
  }));
};

export const uploadAccountLogo = async (accountId, file, previewFile) => {
  const formData = new FormData();

  formData.append('account_id', accountId);
  formData.append('file', file, file.name);
  formData.append('preview_file', previewFile);

  let result = {};
  await axios
    .post(`${apiBaseUrl}/upload-logo`, formData, { withCredentials: true })
    .then((json) => {
      result = json.data;
    })
    .catch(() => {
      result = null;
    });

  return result;
};

export const accountRefresh = async (accountName) => {
  const response = await fetch(`${apiBaseUrl}/account_refresh/${accountName}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};
