/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { Button, Paper, Switch, LinearProgress, Tooltip } from '@mui/material';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import { isValidUser } from '../utils/userValidation';
import caseInsensitiveSort from '../utils/caseInsensitiveSort';
import { GridRoles } from '../utils/roleHelper';
import colors from '../colors';

const useStyles = makeStyles({
  gridButton: {
    color: colors.black,
  },
});

function DataGrid({
  jsonRowData,
  editUser,
  finishEditingUser,
  cancelEditing,
  handleDeleteUser,
  requestInProgress,
  currentUserEmail,
}) {
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();
  const classes = useStyles();

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    // New user added from addUser function will have no id present
    // If the top user has no id, that means a new user is attempting
    // to be added, so take them directly to editing new user
    if (jsonRowData[0].id === '') {
      gridApi.setFocusedCell(0, 'email');
      gridApi.startEditingCell({
        rowIndex: 0,
        colKey: 'email',
      });
    }
  }, [jsonRowData, gridApi]);

  useEffect(() => {
    const resizeColumns = () => {
      gridApi.sizeColumnsToFit();
    };

    window.addEventListener('resize', resizeColumns);

    return () => {
      window.removeEventListener('resize', resizeColumns);
    };
  }, [gridApi]);

  const displayEditOrFinishButton = (params) => {
    if (params.data.isEditing) {
      return (
        <Button
          className={classes.gridButton}
          onClick={() => {
            finishEditingUser(params);
          }}
          data-testid={`confirmButton-${params.data.upn}`}
        >
          <CheckIcon />
        </Button>
      );
    }
    return (
      <Button
        className={classes.gridButton}
        onClick={() => {
          editUser(params);
        }}
        data-testid={`editButton-${params.data.upn}`}
      >
        <EditIcon />
      </Button>
    );
  };

  const displayCancelOrDeleteButton = (params) => {
    const isYou = params.data.email === currentUserEmail;
    if (params.data.isEditing) {
      return (
        <Button
          className={classes.gridButton}
          onClick={() => {
            cancelEditing(params);
          }}
        >
          <CancelIcon />
        </Button>
      );
    }
    return (
      <Tooltip placement="top" title={isYou ? 'You cannot delete yourself' : ''}>
        <span>
          <Button
            className={classes.gridButton}
            onClick={() => {
              handleDeleteUser(params.data);
            }}
            data-testid={`deleteButton-${params.data.upn}`}
            disabled={isYou}
          >
            <DeleteSharpIcon />
          </Button>
        </span>
      </Tooltip>
    );
  };

  const displaySwitchByRole = (params, role) => {
    const adminIsYou = role === 'admin' && params.data.email === currentUserEmail;
    return (
      <Tooltip
        placement="top"
        title={adminIsYou ? 'You cannot revoke Admin role from yourself' : ''}
      >
        <span>
          <Switch
            disabled={!params.data.isEditing || adminIsYou}
            defaultChecked={params.data[role]}
            size="small"
            id={`${params.data.id}-role${role}`}
            data-testid={`slider-${role}-${params.data.upn}`}
            color="secondary"
          />
        </span>
      </Tooltip>
    );
  };

  const displayCredentials = (params, fieldName) => {
    return (
      <Tooltip
        placement="top"
        title={
          params.data.isEditing && params.data.email === currentUserEmail
            ? `You cannot edit your own ${fieldName}`
            : ''
        }
      >
        <div>{params.data[fieldName]}</div>
      </Tooltip>
    );
  };

  // Override tab functionality so that editing cells doesn't carry to the next row.
  // This helps enforce making the user confirm their edit and the table return to the
  // correct state
  const tabToNextCell = (params) => {
    if (
      params.nextCellPosition &&
      params.nextCellPosition.rowIndex === params.previousCellPosition.rowIndex
    ) {
      return params.nextCellPosition;
    }
    return params.previousCellPosition;
  };

  const rowClassRules = (params) => {
    if (isValidUser(params).length !== 0) {
      return 'ag-grid-cell-error';
    }
    return null;
  };

  const arrangeColums = useCallback(() => {
    gridRef.current.columnApi.moveColumns(
      [
        'id',
        'firstName',
        'lastName',
        'email',
        'upn',
        'isEditing',
        'admin',
        'clientLeadership',
        'teamLeadership',
      ],
      0,
    );
  }, [gridRef]);

  useEffect(() => {
    arrangeColums();
  }, [arrangeColums]);

  const onCellClicked = useCallback((params) => {
    if (!params.data.isEditing) return true;

    const currentData = params.data;
    const colId = GridRoles[params.colDef.field];
    if (!colId) return true;

    currentData[colId] = params.event.target.checked;
    return true;
  }, []);

  return (
    <Paper elevation={3}>
      {requestInProgress && <LinearProgress />}
      <div
        className="ag-theme-material"
        style={{ height: 490, width: '100%', paddingRight: '10px' }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={jsonRowData}
          rowSelection="multiple"
          defaultColDef={{ resizable: true }}
          onGridReady={onGridReady}
          editType="fullRow"
          tabToNextCell={tabToNextCell}
          getRowClass={rowClassRules}
          suppressClickEdit
          onCellClicked={onCellClicked}
        >
          <AgGridColumn field="id" hide="true" sortable filter />

          <AgGridColumn
            field="firstName"
            width={130}
            sortable
            comparator={caseInsensitiveSort}
            filter
            editable
          />

          <AgGridColumn
            field="lastName"
            width={130}
            sortable
            comparator={caseInsensitiveSort}
            filter
            editable
          />

          <AgGridColumn
            field="email"
            sortable
            filter
            editable={(params) => params.data.email !== currentUserEmail}
            cellRendererFramework={(params) => displayCredentials(params, 'email')}
          />

          <AgGridColumn
            field="upn"
            sortable
            filter
            editable={(params) => params.data.email !== currentUserEmail}
            cellRendererFramework={(params) => displayCredentials(params, 'upn')}
          />

          <AgGridColumn
            field="admin"
            width={90}
            cellRendererFramework={(params) => displaySwitchByRole(params, 'admin')}
          />
          <AgGridColumn
            field="clientLeadership"
            width={145}
            cellRendererFramework={(params) => displaySwitchByRole(params, 'client')}
          />
          <AgGridColumn
            field="teamLeadership"
            width={145}
            cellRendererFramework={(params) => displaySwitchByRole(params, 'team')}
          />
          <AgGridColumn
            field=""
            width={70}
            cellRendererFramework={(params) => <div>{displayEditOrFinishButton(params)}</div>}
          />
          <AgGridColumn
            field=""
            width={70}
            cellRendererFramework={(params) => <div>{displayCancelOrDeleteButton(params)}</div>}
          />
        </AgGridReact>
      </div>
    </Paper>
  );
}

export default DataGrid;
