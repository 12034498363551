export const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isValidUser = (params, adminUsers = [], addUser = false) => {
  const errors = [];
  if (!params.data.email || !validateEmail(params.data.email)) {
    errors.push('Must be a valid email');
  }
  if (!params.data.upn || !validateEmail(params.data.upn)) {
    errors.push('Must be a valid upn');
  }
  if (!params.data.firstName) {
    errors.push('First name must not be empty');
  }

  if (!params.data.lastName) {
    errors.push('Last name must not be empty');
  }
  if (
    addUser &&
    params.data.upn &&
    adminUsers.find((item) => item.upn === params.data.upn && item.id !== '')
  ) {
    errors.push('The user with this Upn already exists in the list');
  }
  if (!params.data.admin && !params.data.team && !params.data.client) {
    errors.push('At least one role must be selected');
  }
  return errors;
};
