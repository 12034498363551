import React from 'react';

function ReportLegend({ classes }) {
  return (
    <div className={classes.innerLegend}>
      <div className={`${classes.keybox} reports daugherty-count`}>Daugherty</div>
      <div className={`${classes.keybox} reports non-daugherty-count`}>Non-Daugherty</div>
      <div className={`${classes.keybox} reports direct-count`}>Direct Employee</div>
    </div>
  );
}

export default ReportLegend;
