import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  cardArea: {
    padding: '5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerElement: {
    margin: '0px 8px',
  },
  footerEmptyElement: {
    width: '150px',
    margin: '0px 8px',
  },
});
