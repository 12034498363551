import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TextField, Button, Avatar, Box, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { handleLogin } from '../api/Authentication';
import styles from './LoginForm.module.scss';

function LoginForm({ updateUser, history }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameHelper, setUsernameHelper] = useState('');
  const [passwordHelper, setPasswordHelper] = useState('');
  const [errorHelper, setErrorHelper] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setUsernameHelper('');
    setErrorHelper('');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordHelper('');
    setErrorHelper('');
  };

  const validateInput = () => {
    let isValid = true;
    setUsernameHelper('');
    setPasswordHelper('');

    if (username.trim().length === 0) {
      isValid = false;
      setUsernameHelper('Email is required.');
    }
    if (password.trim().length === 0) {
      isValid = false;
      setPasswordHelper('Password is required.');
    }

    return isValid;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateInput()) {
      handleLogin(username, password)
        .then((data) => {
          if (!data.ok) {
            setErrorHelper('Invalid Email or Password');
            return;
          }
          updateUser({
            username,
            isLoggedIn: true,
          });
          history.push('/');
        })
        .catch(() => {
          setErrorHelper('Invalid Email or Password');
        });
    }
  };

  return (
    <div className={styles.container}>
      <Box className={styles.box} />
      <Avatar className={styles.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography
        data-testid="login-header"
        component="h1"
        variant="h1"
        className={styles.typography}
      >
        Log In
      </Typography>

      <form onSubmit={handleFormSubmit}>
        <TextField
          className={styles.input}
          error={usernameHelper !== '' || errorHelper !== ''}
          id="username-input"
          label="Daugherty Email"
          type="email"
          margin="none"
          variant="outlined"
          value={username}
          InputLabelProps={{ shrink: true }}
          onChange={handleUsernameChange}
          helperText={usernameHelper}
        />

        <TextField
          className={styles.input}
          error={passwordHelper !== '' || errorHelper !== ''}
          id="password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          margin="none"
          variant="outlined"
          value={password}
          InputLabelProps={{ shrink: true }}
          onChange={handlePasswordChange}
          helperText={errorHelper !== '' ? errorHelper : passwordHelper}
        />

        <Button
          id="login-button"
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Log In
        </Button>
      </form>
    </div>
  );
}

export default withRouter(LoginForm);
