import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Breadcrumbs, Typography, Link } from '@mui/material';

function BreadcrumbBar({ breadcrumbs, currentEmployee }) {
  const { routeTab, accountId } = useParams();
  function renderBreadcrumbs(nodeList) {
    return nodeList.map((node) => {
      if (node.id === currentEmployee.id) {
        return (
          <Typography key={`crumb_${node.id}`} style={{ fontSize: '0.75em' }} color="textPrimary">
            {node.name}
          </Typography>
        );
      }
      return (
        <Link
          component={RouterLink}
          key={`crumb_${node.id}`}
          to={`/account/${accountId}/${routeTab}/${node.id}`}
        >
          <Typography style={{ fontSize: '0.75em' }} color="textSecondary">
            {node.name}
          </Typography>
        </Link>
      );
    });
  }

  return (
    <Breadcrumbs
      style={{ marginTop: '1rem', marginLeft: '220px' }}
      separator="›"
      aria-label="breadcrumb"
    >
      {renderBreadcrumbs(breadcrumbs.breadcrumbs)}
    </Breadcrumbs>
  );
}

export default BreadcrumbBar;
