import React from 'react';
import { Typography, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import FilterListOutlined from '@mui/icons-material/FilterListOutlined';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

const boxStyle = {
  width: 250,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function SubLevelsToShow({ subLevelsToShow, setSubLevelsToShow }) {
  const [open, setOpen] = React.useState(false);
  const [selectValue, setSelectValue] = React.useState(subLevelsToShow);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef();

  const handleOpen = () => {
    setAnchorEl(divRef.current);
    setOpen(true);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleUpdate = () => {
    // eslint-disable-next-line no-param-reassign
    subLevelsToShow = selectValue;
    setSubLevelsToShow(subLevelsToShow);
    handlePopoverClose();
  };
  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };
  return (
    <div ref={divRef}>
      <Button
        aria-describedby="popover-id"
        variant="outlined"
        startIcon={<FilterListOutlined />}
        endIcon={<ArrowDropDown />}
        onClick={handleOpen}
        color="primary"
      >
        Filter
      </Button>
      <Popover
        id="popover-id"
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={boxStyle}>
          <Typography variant="subtitle2" gutterBottom>
            Display Filters
          </Typography>
          <div>
            <FormControl style={{ minWidth: 120, marginTop: 20 }} size="small">
              <Typography id="discrete-slider" gutterBottom variant="body2">
                Sub Levels
              </Typography>
              <Select
                id="sub-level-select"
                value={selectValue}
                variant="outlined"
                onChange={handleSelectChange}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button
              style={{ width: '100%', marginTop: 20 }}
              variant="contained"
              onClick={handleUpdate}
              color="primary"
            >
              Apply
            </Button>
          </div>
        </Box>
      </Popover>
    </div>
  );
}

export default SubLevelsToShow;
