import { toast } from 'react-toastify';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const messageStyle = { color: '#fff', zIndex: '25', marginRight: '.5em' };
const icon = (iconDefinition) => <FontAwesomeIcon style={messageStyle} icon={iconDefinition} />;

// Show a brief toast message in the UI
// Uses the ToastMessageContainer that's included once for the application
const toastMessage = {
  success: (message) => {
    toast.success(
      <div data-testid="toast-message-success">
        {icon(faCheckCircle)}
        {message}
      </div>,
      { position: toast.POSITION.TOP_CENTER },
    );
  },
  error: (message) => {
    toast.error(
      <div data-testid="toast-message-error">
        {icon(faExclamationCircle)}
        {message}
      </div>,
      { position: toast.POSITION.TOP_CENTER },
    );
  },
  warning: (message) => {
    toast.warning(
      <div data-testid="toast-message-warning">
        {icon(faExclamationCircle)}
        {message}
      </div>,
      { position: toast.POSITION.TOP_CENTER },
    );
  },
};

export default toastMessage;
