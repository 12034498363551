import colors from '../colors';
import linkedInIcon from '../images/linkedin_icon.png';
import salesforceIcon from '../images/salesforce.png';

export default (exportInProgress) => ({
  card: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    boxShadow: '5px 10px 18px #888888',
  },
  topBox: {
    backgroundColor: colors.primary,
    color: 'white',
    minHeight: '4.8em',
    textAlign: 'center',
    paddingBottom: '14px',
    paddingTop: '0.4em',
    margin: '4px',
  },
  text: {
    fontSize: '.7em',
    margin: '0',
  },
  personName: {
    fontWeight: '700',
    margin: '0 0 4px',
    fontSize: '.8em',
  },
  personTitle: {
    fontStyle: 'italic',
    fontSize: '.7em',
    margin: '0',
  },
  los: {
    fontSize: '.7em',
    margin: '0',
  },
  reportsTo: {
    display: 'flex',
    color: 'rgb(84, 140, 63)',
  },
  personRole: {
    fontSize: '.7em',
    margin: '0',
  },
  label: {
    fontWeight: '700',
    color: colors.darkGrey,
  },
  textAlignStart: {
    textAlign: 'start',
  },
  footer: {
    paddingTop: '.6em',
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: '2px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  counters: {
    paddingTop: '.3em',
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: '2px',
  },
  reportCount: {
    color: 'white',
    fontSize: '1rem',
    width: '4rem',
    borderRadius: '0.5rem',
    textAlign: 'center',
    margin: '0 2px 0 2px !important',
  },
  employeeNodeContainer: {
    padding: '0 5px',
  },
  employeeInfoContainer: {
    minHeight: '4.5em',
    paddingTop: '0.4em',
    marginTop: '4px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: `${!exportInProgress ? 28 : 16}px`,
    paddingBottom: '13px',
    position: 'relative',
  },
  employeeName: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    margin: '0 0 4px 0',
  },
  employeeTitle: {
    fontSize: '0.7em',
    fontStyle: 'italic',
    flexGrow: 1,
    margin: 0,
  },
  employeeDepartment: {
    fontSize: '0.7em',
    flexGrow: 1,
    margin: '3px 0',
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(50%)',
  },
  linkedInPng: {
    backgroundImage: `url("${linkedInIcon}")`,
    margin: '0 4px',
  },
  salesforcePng: {
    backgroundImage: `url("${salesforceIcon}")`,
    margin: '0 4px',
  },
  iconSpan: {
    display: 'flex',
    width: '20px',
    height: '20px',
    backgroundSize: '20px',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  nodeFooterContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    fontSize: '1rem',
  },
  footerContainerEmployee: {
    marginBottom: '15px',
  },
  footerContainerDbsConsultant: {
    zIndex: 1,
    textAlign: 'center',
    height: 0,
  },
  nodeFooterCounters: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  reports: {
    color: colors.white,
  },
  pills: {
    width: '3.5rem',
    fontSize: '0.9em',
    borderRadius: '1rem',
    margin: '0 2px 0 2px !important',
  },
  daughertyCount: {
    background: colors.$daughertyDarker,
  },
  nonDaughertyCount: {
    background: colors.nonDaugherty,
  },
  directCount: {
    background: colors.directEmployee,
  },
  relTrustedAdvisor: {
    backgroundColor: '#b3e06e',
  },
  relFavorable: {
    backgroundColor: '#9eb7dc',
  },
  relNeutral: {
    backgroundColor: '#dedede',
  },
  relTarget: {
    backgroundColor: '#c5bedd',
  },
  relUnfavorable: {
    backgroundColor: '#f6c76f',
  },
});
