// TODO: write tests for this?
import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';
import { getAccounts } from '../api/Accounts';

const AccountContext = createContext();
export const useAccount = () => useContext(AccountContext);

export function AccountProvider({ children }) {
  const [activeAccount, setAccount] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  const refreshAccounts = async () => {
    setIsLoadingAccounts(true);
    return getAccounts()
      .then(setAccounts)
      .finally(() => setIsLoadingAccounts(false));
  };

  const loadAccounts = useCallback(async () => {
    if (!accounts.length) {
      await refreshAccounts();
    }
  }, [accounts.length]);

  const setByName = useCallback(
    (name) => {
      if (activeAccount.name !== name) {
        const newAccount = accounts.find((account) => account.name === name);
        if (newAccount) {
          setAccount(newAccount);
        }
      }
    },
    [accounts, activeAccount.name],
  );

  const setByTimeStamp = useCallback(
    (acc, name) => {
      const newAccount = accounts.find((account) => account.name === name);
      if (newAccount) {
        setAccount(acc);
      }
    },
    [accounts],
  );

  const setAccountByName = useCallback(
    async (name, isSubscribed = true) => {
      await loadAccounts();
      if (isSubscribed) setByName(name);
    },
    [loadAccounts, setByName],
  );

  const setActiveAccount = useCallback(
    async (newAccount, isSubscribed = true) => {
      if (newAccount && newAccount.name) {
        setAccountByName(newAccount.name, isSubscribed);
      }
    },
    [setAccountByName],
  );

  const clearActiveAccount = useCallback(() => {
    if (activeAccount.name) {
      setAccount({});
    }
  }, [activeAccount.name]);

  const updateAccount = useCallback(
    (updatedAccount) => {
      const newAccounts = accounts.filter((account) => account.id !== updatedAccount.id);
      setAccounts(newAccounts.concat([updatedAccount]));
      setByName(activeAccount.name);
      setByTimeStamp(updatedAccount, activeAccount.name);
    },
    [accounts, activeAccount.name, setByName, setByTimeStamp],
  );

  const accountContextValue = useMemo(
    () => ({
      accounts,
      isLoadingAccounts,
      activeAccount,
      loadAccounts,
      setActiveAccount,
      setAccountByName,
      clearActiveAccount,
      updateAccount,
    }),
    [
      accounts,
      isLoadingAccounts,
      activeAccount,
      loadAccounts,
      setActiveAccount,
      setAccountByName,
      clearActiveAccount,
      updateAccount,
    ],
  );

  return <AccountContext.Provider value={accountContextValue}>{children}</AccountContext.Provider>;
}
