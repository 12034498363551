import React from 'react';
// Hard to fix - DaughertyOrgChartNode relies on DaughertyNodesSubset and vice-versa
// eslint-disable-next-line import/no-cycle
import DaughertyOrgChartNode from './DaughertyOrgChartNode';

function DaughertyNodesSubset({ directReports, subLevelsToShow, handleSelectedEmployeeId }) {
  return (
    <>
      <div className="sideways-bracket">
        <svg className="org-chart-svg">
          <line x1="0%" y1="0%" x2="100%" y2="0%" stroke="#548c3f" />
          <line x1="0%" y1="0%" x2="0%" y2="15px" stroke="#548c3f" />
          <line x1="100%" y1="0%" x2="100%" y2="15px" stroke="#548c3f" />
          <line x1="50%" y1="0%" x2="50%" y2="-15px" stroke="#548c3f" />
        </svg>
      </div>
      <div className="d-org-chart-wrapper">
        {directReports.map((employee) => {
          return (
            <DaughertyOrgChartNode
              key={employee.id}
              id={employee.id}
              title={employee.title}
              name={`${employee.firstName} ${employee.lastName}`}
              department={employee.department}
              lastContactDate={employee.last_contact_date}
              directReports={employee.children}
              subLevelsToShow={subLevelsToShow - 1}
              handleSelectedEmployeeId={handleSelectedEmployeeId}
              employeeData={employee} // TODO: see https://daugherty.atlassian.net/browse/CPOC1-1097
            />
          );
        })}
      </div>
    </>
  );
}

export default DaughertyNodesSubset;
