import React, { useContext } from 'react';
import { Switch, Route, Link, withRouter, useRouteMatch } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Tabs, Tab, CircularProgress } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AdminDataRefresh from '../components/AdminDataRefresh';
import AdminUserDetails from '../components/AdminUserDetails';
import AdminUserContext from '../contexts/AdminUserContext';
import AccountEdit from '../components/AccountEdit';

const useStyles = makeStyles((theme) => ({
  iconLabelWrapper: {
    flexDirection: 'row',
    justifyContent: 'start',
    zIndex: '20',
  },
  icon: {
    marginBottom: 0,
    marginRight: '.2em',
  },
  labelIcon: {
    minHeight: '48px',
  },
  tabsContainer: { minWidth: '170px' },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
  },
  settingsContainer: {
    padding: '1.5em',
    justifyContent: 'space-between',
    flexGrow: 1,
    display: 'flex',
  },
  main: {
    width: '85%',
    padding: '2em',
  },
  loader: {
    margin: theme.spacing(3),
  },
}));

function Settings({ styles, setLoadingContacts, location }) {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { isAdminUser, isClientUser } = useContext(AdminUserContext);
  const allTabs = [`${url}/refresh`, `${url}/organization`, `${url}/users`];
  const areRolesDefined = typeof isAdminUser !== 'undefined' && typeof isClientUser !== 'undefined';
  const isRoleProperForRoute = (pathUrl) => {
    return (
      areRolesDefined &&
      ((pathUrl === '/settings/refresh' && isAdminUser) ||
        (pathUrl === '/settings/organization' && isClientUser) ||
        (pathUrl === '/settings/users' && isAdminUser))
    );
  };

  return (
    <div className={styles.root}>
      {areRolesDefined ? (
        <div className={classes.settingsContainer}>
          <Paper className={classes.tabsContainer}>
            {isRoleProperForRoute(location.pathname) && (
              <Tabs
                value={location.pathname}
                orientation="vertical"
                variant="scrollable"
                aria-label="Vertical tabs example"
                className={classes.tabs}
              >
                {isAdminUser && (
                  <Tab
                    data-testid="refresh-all"
                    label="Refresh All"
                    value={allTabs[0]}
                    component={Link}
                    to={allTabs[0]}
                    classes={{ wrapper: classes.iconLabelWrapper, labelIcon: classes.labelIcon }}
                    icon={<SettingsIcon />}
                    iconPosition="start"
                  />
                )}
                {isClientUser && (
                  <Tab
                    data-testid="client-logo"
                    label="Client Logo"
                    value={allTabs[1]}
                    component={Link}
                    to={allTabs[1]}
                    classes={{ wrapper: classes.iconLabelWrapper, labelIcon: classes.labelIcon }}
                    icon={<BusinessIcon style={{ marginBottom: 0, marginRight: '.2em' }} />}
                    iconPosition="start"
                  />
                )}
                {isAdminUser && (
                  <Tab
                    label="User Roles"
                    value={allTabs[2]}
                    component={Link}
                    to={allTabs[2]}
                    classes={{ wrapper: classes.iconLabelWrapper, labelIcon: classes.labelIcon }}
                    icon={<GroupAddIcon style={{ marginBottom: 0, marginRight: '.2em' }} />}
                    iconPosition="start"
                  />
                )}
              </Tabs>
            )}
          </Paper>
          <Paper className={classes.main}>
            <Switch>
              <Route
                path={allTabs[0]}
                render={() => <AdminDataRefresh setLoadingContacts={setLoadingContacts} />}
              />
              <Route path={allTabs[1]} render={() => <AccountEdit />} />
              <Route path={allTabs[2]} render={() => <AdminUserDetails />} />
            </Switch>
          </Paper>
        </div>
      ) : (
        <CircularProgress className={classes.loader} />
      )}
    </div>
  );
}

export default withRouter(Settings);
